import React from 'react';
import css from '../TermsOfServicePage.module.css';

function PatientTermsGreek() {
  return (
    <div className={css.pageContent}>
      <h3>ΟΡΟΙ ΧΡΗΣΗΣ</h3>
      <h4>1. Προοίμιο</h4>
      <p>
        Με το παρόν καθορίζονται οι όροι χρήσης των υπηρεσιών μεσολάβησης που παρέχονται μέσω της
        ιστοσελίδας που βρίσκεται στη διαδικτυακή διεύθυνση www.vivianlab.gr & www.vivianlab.com &
        www.vivianlab.health ή στην σχετική εφαρμογή (εφ' εξής «Επιχείρηση» ή «Vivian Lab» ως
        διακριτικός τίτλος της Εταιρείας).
        <br />
        O παραπάνω δικτυακός ιστότοπος & ιστοσελίδα (εφεξής ο «Ιστότοπος» ή «Ιστοσελίδα»)
        δημιουργήθηκε, ανήκει και λειτουργεί υπό τη διαχείριση της εταιρείας με την επωνυμία
        «ΒΙΒΙΑΝΛΑΜΠ ΜΟΝΟΠΡΟΣΩΠΗ Ι.Κ.Ε.» και το διακριτικό τίτλο «Vivian Lab», με έδρα στο Μαρούσι
        Αττικής, οδός Σάμου αρ. 47, με αρ. Γ.Ε.ΜΗ. 179795401000, Α.Φ.Μ. 802625789, Δ.Ο.Υ.
        Αμαρουσίου, ηλεκτρονική διεύθυνση επικοινωνίας ως ανωτέρω ορίζεται info@vivianlab.com, η δε
        εταιρεία αποτελεί και την αποκλειστική νόμιμη δικαιούχο της παρούσας Ιστοσελίδας, του
        περιεχομένου και του ονόματος χώρου υπό τη διεθνή διαδικτυακή καταχώρηση ως εξής:
        www.vivianlab.gr & www.vivianlab.com & www.vivianlab.health, και κάθε άλλη παρεμφερή
        ονομασία με το οποίο αυτή συνδέεται ή επιλέξει να συνδεθεί στο μέλλον. Το γενικό περιεχόμενο
        προστατεύεται από την νομοθεσία περί πνευματικής ιδιοκτησίας, καθώς και ειδικότερα στοιχεία
        των ιστοσελίδων προστατεύονται από νόμους που σχετίζονται ενδεικτικά και όχι περιοριστικά με
        εμπορικό απόρρητο, εμπορικών & βιομηχανικών μυστικών και περιουσίας, αθέμιτου ανταγωνισμού,
        ηλεκτρονικού εμπορίου, ασφάλειας οικονομικών συναλλαγών και έτερες σχετικές νομοθεσίες. Δεν
        επιτρέπεται η τροποποίηση, αντιγραφή η μίμηση τους, και δεν πρέπει να αφαιρείται ή να
        τροποποιείται οποιαδήποτε ειδοποίηση περί πνευματικών δικαιωμάτων ή εμπορικών σημάτων, ούτε
        κάποια άλλη ειδοποίηση για την ιδιοκτησία.
        <br />
        Το VivianLab αποτελεί έναν καινοτόμο διαδικτυακό τόπο, ο οποίος εξειδικεύεται στο χώρο της
        συμβουλευτικής υγείας σε ένα γενικό και ολιστικό πλαίσιο υπό τη μορφή καταχώρησης και
        προβολής επαγγελματιών όλων των κλάδων της υγείας καθώς επίσης και επιχειρήσεων και
        επιστημών που δραστηριοποιούνται στο χώρο της υγείας σε μία ευρύτερη μορφή και ενασχόληση.
        Επομένως, το VivianLab είναι διαδικτυακός τόπος προβολής επαγγελματιών που μάλιστα
        συμπεριλαμβάνει πέραν των ιατρών και επιπλέον κατηγορίες επαγγελματιών και επιστημόνων που
        άπτονται του χώρου της υγείας, σε μια προσπάθεια να παρέχει στον τελικό χρήστη μια
        ολοκληρωμένη λύση στην προσπάθεια αναζήτησης συμβουλής και βοήθειας για κάθε ζήτημα υγείας,
        αυτοβελτίωσης, συμβουλευτικής και ενισχυτικής ψυχολογίας, φαρμακευτικής ή/και
        παραφαρμακευτικής κάλυψης και ούτω καθεξής.
        <br />
        Επισημαίνεται ότι μέσω αυτού του διαδικτυακού τόπου, ο οποίος λειτουργεί αυστηρά ως
        διαμεσολαβητής, και όχι ως πάροχος, παρέχεται πλήθος υπηρεσιών με το χαρακτήρα της
        τήλε-συμβουλευτικής καθώς επίσης και η δυνατότητα καταχώρησης ραντεβού online, αποφεύγοντας
        άσκοπα τηλεφωνήματα και μετακινήσεις.
      </p>
      <p>
        1.2. Ο επισκέπτης, ο χρήστης ή το εγγεγραμμένο μέλος του Ιστοτόπου (εφεξής καλούμενοι
        άπαντες οι ανωτέρω χάριν συντομίας "Αποδέκτης" και η χρήση του όρου αυτού στο παρόν θα
        λογίζεται ότι αναφέρεται συνολικά σε όλους τους ανωτέρω), ως και ο Επαγγελματίας Χρήστης ή
        εγγεγραμμένο μέλος του Ιστοτόπου, ο οποίος προβάλλεται ή καταχωρίζεται στον Ιστότοπο (εφεξής
        καλούμενος χάριν συντομίας «Επαγγελματίας Αποδέκτης») θα πρέπει να διαβάσει προσεκτικά και
        λέξη προς λέξη και να κατανοήσει τους όρους και τις προϋποθέσεις χρήσης της ιστοσελίδας, το
        προσάρτημα περί πνευματικής ιδιοκτησίας, το προσάρτημα περί προσωπικών δεδομένων και το
        προσάρτημα περί της πολιτικής απορρήτου που ακολουθούν, τα οποία αποτελούν ένα αδιαίρετο και
        αδιάσπαστο σύνολο με το παρόν, πριν από την επίσκεψη ή την χρήση των σελίδων μας και των
        παρεχόμενων υπηρεσιών, κατανοώντας ότι όλα τα ανωτέρω αποτελούν νομικά δεσμευτική συμφωνία
        μεταξύ αυτού και της Εταιρείας, η αναντίρρητη αποδοχή της οποίας συνιστά απαραίτητη
        προϋπόθεση για την πλοήγηση στην Ιστοσελίδα και την χρήση των Υπηρεσιών αυτής.
      </p>
      <p>
        1.3 Ως νόμιμη χρήση των παρεχομένων υπηρεσιών, εφαρμογών, προϊόντων, συμβουλών και
        ανταλλαγής απόψεων και σχολίων (blog) νοείται η ανεπιφύλακτη αποδοχή των όρων του παρόντος
        και η συμμόρφωσή σας κατά τη χρήση των υπηρεσιών αυτών με την κείμενη ελληνική νομοθεσία που
        διέπει τις εν λόγω συναλλαγές.
        <br />
        Προϋπόθεση για την εγγραφή και την χρήση των υπηρεσιών της Ιστοσελίδας αποτελεί ο χρήστης να
        είναι άνω των 18 ετών και να έχει πλήρη δικαιοπρακτική ικανότητα.
      </p>
      <p>
        1.4. Ο Ιστότοπος έχει δικαίωμα να τροποποιεί οποτεδήποτε το κρίνει αναγκαίο τους όρους και
        της προϋποθέσεις χρήσης του και ο αποδέκτης των υπηρεσιών του οφείλει να ελέγχει κάθε φορά
        για τυχόν επ' αυτών αλλαγές που έχουν πραγματοποιηθεί, και εφόσον εξακολουθούν την επίσκεψη
        ή την χρήση στον διαδικτυακό μας τόπο τεκμαίρεται ότι αποδέχεται ανεπιφύλακτα οποιαδήποτε
        αλλαγή έχει επ' αυτών επέλθει.
      </p>
      <p>
        1.5. Ακολούθως, παρακαλούμε όπως διαβάσετε προσεκτικά αυτούς τους όρους χρήσης προτού
        χρησιμοποιήσετε την Ιστοσελίδα μας καθώς αυτοί οι όροι χρήσης Ιστοσελίδας διέπουν την
        πρόσβασή σας και τη χρήση αυτής. Επομένως, η ιστοσελίδα είναι διαθέσιμη προς χρήση μόνον
        εφόσον ο χρήστης συμφωνεί με τους όρους και τις προϋποθέσεις που αναφέρονται στη συνέχεια.
        Σημειώνουμε ότι σε περίπτωση που δεν συμφωνείτε με όλους τους όρους χρήσης δεν επιτρέπεται η
        πρόσβασή σας και η εν γένει χρήση της Ιστοσελίδας μας. Αντιθέτως, με την πρόσβασή σας στην
        Ιστοσελίδα, την παραμονή και χρήση αυτής, ο χρήστης καθώς και πάσης φύσεως έτερο πρόσωπο
        εξουσιοδοτημένο από αυτών δηλώνετε ότι συμφωνείτε και συμμορφώνεστε με τους παρόντες όρους
        χρήσης.
        <br />
        Εν κατακλείδι, καθίσταται σαφές ότι οι παρόντες Όροι Χρήσης δεσμεύουν απόλυτα και κάθε απλό
        επισκέπτη του Ιστοτόπου κατά το μέγιστο δυνατό βαθμό και στην έκταση που μπορούν να τύχουν
        εφαρμογής, όπου, δε, αναφέρεται ο όρος «χρήση», αυτός περιλαμβάνει σε κάθε περίπτωση και την
        απλή επίσκεψη και περιήγηση στην Ιστοσελίδα.
      </p>
      <h4>2. Ιδιωτικότητα</h4>
      <p>
        Η Πολιτική Απορρήτου μας περιγράφει τον τρόπο με τον οποίο η Επιχείρηση διαχειρίζεται τα
        δεδομένα των χρηστών των υπηρεσιών της. Όπως περιγράφεται στην Πολιτική Απορρήτου, η συλλογή
        και περαιτέρω επεξεργασία των δεδομένων αυτών είναι απαραίτητη, τόσο στο πλαίσιο της χρήσης
        των υπηρεσιών που παρέχει η Ιστοσελίδα, όσο και για άλλους νόμιμους σκοπούς που αναλύονται
        στην Πολιτική Απορρήτου μας.
        <br />
        H διαχείριση και προστασία των προσωπικών δεδομένων του επισκέπτη και των μελών της
        Ιστοσελίδας διέπεται από τους παρόντες Όρους, την Πολιτική Απορρήτου, καθώς και τις σχετικές
        διατάξεις της ελληνικής και ευρωπαϊκής νομοθεσίας. Σε κάθε περίπτωση η Ιστοσελίδα διατηρεί
        το δικαίωμα αλλαγής των όρων προστασίας των προσωπικών δεδομένων, εντός των πλαισίων της
        κείμενης νομοθεσίας.
        <br />Η Επιχείρηση δεν μπορεί να εγγυηθεί την αδιάκοπη και χωρίς τεχνικά σφάλματα λειτουργία
        της Ιστοσελίδας και ουδεμία ευθύνη φέρει έναντι των χρηστών για τυχόν ζημιές, που προκύψουν
        από την μη ομαλή λειτουργία ή την διακοπή πρόσβασης σε αυτές. Ωστόσο, θα πράξει οτιδήποτε
        είναι τεχνικά εφικτό για την αποκατάσταση της βλάβης.
      </p>
      <h4>3. Η Λειτουργία της Ιστοσελίδας συνίσταται, μεταξύ άλλων, στα κάτωθι:</h4>
      <p>
        3.1 Online παρακολούθηση οπτικοακουστικών μέσων (εκπαιδευτικά βίντεο), ανάγνωση άρθρων,
        συμμετοχή σε group webinars, καταγραφή συμπτωμάτων, πλάνα διατροφής και ασκήσεων, συμβουλές,
        καθώς και hotlines με ειδικούς για εξειδικευμένες ερωτήσεις.
      </p>
      <p>
        3.2 Πραγματοποίηση επιλογών παροχής υπηρεσιών από συνεργάτες / providers
        <br />
        α) Online περιήγηση, ενημέρωση και προβολή επαγγελματιών και επιχειρήσεων που παρέχουν
        υπηρεσίες.
        <br />
        β) Δυνατότητα με ποικίλους τρόπους online αναζήτησης και σύγκρισης υπηρεσιών ανάλογα με τα
        χαρακτηριστικά τους (εξειδίκευση, διαθεσιμότητα, ανταπόκριση, τρόπους τιμολόγησης κλπ.).
        <br />
        γ) Δυνατότητας online διαβίβασης εντολής λήψης διαθεσιμότητας και επιβεβαίωσης παροχής της
        επιλεγμένης υπηρεσίας.
        <br />
        δ) Διαμόρφωση της επιλογής και της εντολής παροχής υπηρεσίας.
        <br />
        ε) online πληρωμή.
      </p>
      <p>
        3.3 Άμεση εξυπηρέτηση και μεταφορά της επιλογής της υπηρεσίας μέσω της ηλεκτρονικής
        πλατφόρμας της εταιρείας από δίκτυο συνεργατών και συνεργαζόμενων τρίτων εταιρειών /
        επιχειρήσεων.
        <br />
        3.4 Παροχή εξυπηρέτησης και διεκπεραίωσης των επιλογών του χρήστη από τρίτες ή/και
        συνεργαζόμενες επιχειρήσεις ή/και ιδιώτες, μέσω της ηλεκτρονικής πλατφόρμας της εταιρείας
        από δίκτυο συνεργατών και συνεργαζόμενων επιχειρήσεων.
        <br />
        3.5 Παροχή ηλεκτρονικής πλατφόρμας για την αξιολόγηση των παρεχόμενων υπηρεσιών από τις
        συνεργαζόμενες επιχειρήσεις και συνεργάτες και δημιουργία βάσης δεδομένων και αποθήκευσης
        των δεδομένων αυτών με σκοπό την επεξεργασία τους για σκοπό της εταιρείας όπως συμμορφώνεται
        με τους ΓΚΠΔ.
        <br />
        3.6 Στατιστική αξιολόγηση των δεδομένων προς χρήση από τις συνεργαζόμενες επιχειρήσεις και
        συνεργάτες που αφορούν τους αποδέκτες ή και τρίτους.
        <br />
        3.7 Η ιστοσελίδα μπορεί να διαθέσει ανά διαστήματα ή/και σε μόνιμη βάση διαφημιστικό χώρο
        για σκοπό δικό της ή/και τρίτους σε οποιαδήποτε σελίδα επιθυμεί ή κρίνει εύλογη η ίδια.
        <br />
        3.8 Η Εταιρεία διατηρεί το δικαίωμα να αφαιρέσει, να τροποποιήσει, να εμπλουτίσει ή να
        μεταβάλλει καθ' οιονδήποτε τρόπο από την Ιστοσελίδα καθεμία από τις ανωτέρω υπηρεσίες εν όλω
        ή εν μέρει ή να προσθέσει και νέες Υπηρεσίες στα πλαίσια του σκοπού της και των προοπτικών
        ανάπτυξής της.
      </p>
      <h4>4. Πρόσβαση Στην Ιστοσελίδα Και Εγγραφή Μελών</h4>
      <p>
        Μόνο οι εγγεγραμμένοι χρήστες έχουν τη δυνατότητα να κάνουν χρήση των Υπηρεσιών του Vivian
        Lab. Κάθε χρήστης μπορεί να δημιουργήσει τον προσωπικό του λογαριασμό συμπληρώνοντας τα
        ζητούμενα πεδία στην φόρμα εγγραφής της Ιστοσελίδας. Ωστόσο, αναγνωρίζετε ότι το Vivian Lab
        διατηρεί τη διακριτική ευχέρεια, και χωρίς να φέρει καμία ευθύνη, δύναται να απορρίπτει
        αιτήματα για δημιουργία λογαριασμού.
        <br />
        Ο χρήστης για τη δημιουργία προσωπικού λογαριασμού στην Ιστοσελίδα οφείλει να καταχωρήσει
        κατ' αρχάς τα ακόλουθα στοιχεία:
        <br />
        - Όνομα χρήστη
        <br />
        - Έγκυρη και ενεργή διεύθυνση ηλεκτρονικού ταχυδρομείου, διευθυνση κατοικίας και τηλέφωνο
        που ανήκουν νομίμως στον ίδιο.
        <br />
        - Μοναδικό κωδικό πρόσβασης τον οποίο επιλέγει και εισάγει ο χρήστης στην Ιστοσελίδα. Ο
        χρήστης οφείλει να τον διατηρεί μυστικό, να μην τον αποκαλύπτει σε τρίτους και να λαμβάνει
        κατάλληλα μέτρα για την αποφυγή διαρροής του σε τρίτους, να αλλάζει τον κωδικό πρόσβασης σε
        περίπτωση που πιθανολογεί ή διαπιστώσει ότι αυτός έχει διαρρεύσει αλλά και σε τακτά χρονικά
        διαστήματα για λόγους ασφαλείας και να μην χρησιμοποιήσει ποτέ και για οποιοδήποτε λόγο
        κωδικό πρόσβασης, που ανήκει σε άλλον χρήστη, που ενδέχεται να περιήλθε σε γνώση του με
        οποιοδήποτε τρόπο.
        <br />
        Αντί των παραπάνω και προς διευκόλυνσή του, ο χρήστης έχει τη δυνατότητα να κάνει γρήγορη
        εγγραφή ή/και είσοδο στην ιστοσελίδα (sign & log in) χρησιμοποιώντας το προφίλ που έχει
        δημιουργήσει στο Facebook (Meta) ή στη Google. Η ιστοσελίδα, στην περίπτωση αυτή θα
        αναγνωρίζει και θα χρησιμοποιεί το εκεί καταχωρημένο δημόσιο προφίλ του χρήστη
        (public_profile) και καθώς και τις εκεί καταχωρημένες λοιπές πληροφορίες, όπως τη διεύθυνση
        ηλεκτρονικής αλληλογραφίας (email) του.
      </p>
      <h4>5. Γενικές Υποχρεώσεις Επισκέπτη, Χρήστη Η΄ Εγγεγραμμένου Μέλους</h4>
      <p>
        • επισκέπτης, ο χρήστης καθώς και το εγγεγραμμένο μέλος του Ιστοτόπου (εφεξής καλούμενοι
        άπαντες οι ανωτέρω χάριν συντομίας "Αποδέκτης" και η χρήση του όρου αυτού στο παρόν θα
        λογίζεται ότι αναφέρεται συνολικά σε όλους τους ανωτέρω), θα πρέπει να είναι ενήλικας και να
        έχει πλήρη δικαιοπρακτική ικανότητα. Η χρήση των Υπηρεσιών του Ιστοτόπου θα πρέπει να
        γίνεται αποκλειστικά από πρόσωπα που έχουν συμπληρώσει το 18ο έτος της ηλικίας τους. Ο
        γονέας ή ο νόμιμος κηδεμόνας του ανηλίκου φέρει την αποκλειστική ευθύνη για την χρήση των
        Υπηρεσιών της Ιστοσελίδας από ανηλίκους που βρίσκονται στην κηδεμονία του. Σε περίπτωση που
        δεν καλύπτετε τις προϋποθέσεις αυτές δεν επιτρέπεται να χρησιμοποιείτε την ιστοσελίδα.
        <br />
        • Ο χρήστης φέρει την αποκλειστική ευθύνη για την εξασφάλιση κωδικών και την αποφυγή κάθε
        ανάρμοστης συμπεριφοράς όσον αφορά στη χρήση τέτοιων κωδικών που πιθανόν να έχει λάβει για
        χρήση στο δικτυακό τόπο. Στην περίπτωση που ο χρήστης ανακαλύψει ότι ο κωδικός του έχει
        διατεθεί ακούσια σε οποιοδήποτε τρίτο άτομο που δεν έχει δικαιοδοσία ή ότι υφίσταται
        κίνδυνος κακής χρήσης, οφείλει να ενημερώνει άμεσα τον ιστότοπο με απλό email σε μία από τις
        εξής διευθύνσεις όπως αυτές ακολουθούν αμέσως κατωτέρω: www.vivianlab.gr & www.vivianlab.com
        & www.vivianlab.health έτσι ώστε να αποκλειστεί η πρόσβαση οποιουδήποτε τρίτου στον εν λόγω
        κωδικό. Σε κάθε περίπτωση πάντως, η εταιρεία δε φέρει καμία ευθύνη για καταβολή αποζημίωσης
        για την κακή χρήση οποιουδήποτε Προσωπικού Κωδικού. Επιπλέον, η εταιρεία διατηρεί το
        δικαίωμα, οποιαδήποτε στιγμή και χωρίς να υποχρεούται να διευκρινίσει τους λόγους, να
        ακυρώσει ή να περιορίσει το δικαίωμα πρόσβασης του Χρήστη στην περιοχή που προστατεύεται με
        κωδικό αποκλείοντας τα Δεδομένα Χρήστη.
        <br />
        • Ο Αποδέκτης οφείλει να συμμορφώνεται με την κείμενη ελληνική και Ευρωπαϊκή Νομοθεσία,
        καθώς και με τις κυρωμένες Διεθνείς Συμβάσεις που αποτελούν αναπόσπαστο μέρος του ελληνικού
        δικαίου και να τηρεί τον Κώδικα Δεοντολογικής συμπεριφοράς στο Διαδίκτυο όπως ισχύει,
        συμβάλλοντας κατά το μέτρο του δυνατού στην ομαλότερη και αποδοτικότερη αλληλεπίδραση μεταξύ
        των χρηστών του διαδικτυακού μας τόπου όσο και μεταξύ των χρηστών και των διαχειριστών
        αυτού.
        <br />
        • Ο Αποδέκτης οφείλει να απέχει από κάθε παράνομη συμπεριφορά ή καταχρηστική ενέργεια κατά
        την επίσκεψή του στον Ιστότοπο ή την χρήση των παρερχομένων από αυτόν υπηρεσιών.
        Απαγορεύεται ακόμα ρητά η υιοθέτηση από τον Αποδέκτη πράξεων που συντείνουν σε αθέμιτο
        ανταγωνισμό ή παράβαση του παρόντος ή κάθε ειδικότερης σύμβασης που ενδέχεται να συνάψει η
        Εταιρεία με τον Αποδέκτη και γενικά απαγορεύεται οποιαδήποτε άλλη αθέμιτη ή παράνομη
        πρακτική, επιφυλασσόμενης της Εταιρείας να ασκήσει κάθε νόμιμο δικαίωμά της για την
        διασφάλιση των συμφερόντων της.
        <br />
        • Ο Αποδέκτης δεσμεύεται ότι θα παρέχει προς τον Ιστότοπο τα πραγματικά του προσωπικά
        στοιχεία, τον οποίο βαρύνει απολύτως και η σχετική ευθύνη. Ειδικότερα, τα στοιχεία αυτά, ως
        και κάθε άλλη πληροφορία ή ψηφιακό αρχείο που θα διαθέσει στον Ιστότοπο δεν θα πρέπει σε
        καμία περίπτωση να είναι ψευδή, παραπλανητικά ή ανακριβή, ούτε να οδηγούν, άμεσα ή έμμεσα,
        σε πρόκληση αναληθών παραστάσεων σε τρίτους, ούτε με κανένα τρόπο να παραβιάζουν τις
        ισχύουσες διατάξεις του Συντάγματος και του Αστικού, Ποινικού και Εμπορικού Δικαίου, ή να
        γεννούν κάθε είδους διακρίσεις που βαίνουν σε βάρος της αξίας του ανθρώπου ως και κάθε
        μορφής ζωής, ενώ θα πρέπει να είναι σύμφωνα με το σύνολο της ισχύουσας νομοθεσίας σε εθνικό,
        ευρωπαϊκό και διεθνές επίπεδο.
        <br />
        • Ο Αποδέκτης συναινεί ρητά διά του παρόντος και με την παροχή των ως άνω στοιχείων και
        προσωπικών δεδομένων ότι παρέχει προς την Εταιρεία την ρητή συναίνεσή του αυτά να τηρηθούν
        σε βάση δεδομένων που η Εταιρεία τηρεί και να χρησιμοποιηθούν τόσο από την τελευταία και από
        κάθε πρόσωπο που έλκει δικαιώματα από αυτήν για κάθε σχετική με την λειτουργία του Ιστοτόπου
        επικοινωνία, ως και από κάθε τρίτο πρόσωπο, Επαγγελματία ή Συνεργάτη της, κατόπιν αιτήματός
        του για την παροχή κάποιας Υπηρεσίας από αυτόν ή την παροχή οικονομικής προσφοράς. Η
        επικοινωνία θα μπορεί να πραγματοποιείται με κάθε πρόσφορο μέσο, όπως e-mail,
        βιντεοδιάσκεψη, ανταλλαγή μηνυμάτων ή τηλεφωνική κλήση.
        <br />
        • Ο Αποδέκτης δεσμεύεται ότι θα χρησιμοποιεί το περιεχόμενο του δικτυακού τόπου,
        συμπεριλαμβανομένων των παρεχόμενων στοιχείων των επαγγελματιών ή των χρηστών ή μελών του
        Ιστοτόπου, των χαρτών, φωτογραφιών ή video, μόνο για την ενδεδειγμένη και εγκεκριμένη από
        την Εταιρεία ανά περίπτωση χρήση.
        <br />
        • O Αποδέκτης δεσμεύεται ότι θα μεριμνά ώστε όλες οι πληροφορίες ή τα ψηφιακά αρχεία που θα
        αναρτά ή θα διοχετεύει στον Ιστότοπο θα είναι απολύτως ελεγμένα με δική του μέριμνα ώστε να
        μην περιέχουν οποιοδήποτε κακόβουλο ή εν γένει βλαβερό λογισμικό (malware) που μπορεί να
        προκαλέσει οποιαδήποτε ζημία ή καθυστέρηση στους servers ή τα συστήματα της Εταιρείας καθώς
        και οιουδήποτε τρίτου με τον οποίο αυτή συνεργάζεται, και στους ηλεκτρονικούς υπολογιστές,
        τις ηλεκτρονικές συσκευές ή τους servers των λοιπών επισκεπτών, χρηστών ή μελών του
        Ιστοτόπου, όπως ενδεικτικά ιούς, trojans, worms κ.λπ., ούτε κάποιο άλλο αρχείο ή λογισμικό
        που θα παραβιάζει την ιδιωτικότητα ή τα προσωπικά δεδομένα της Εταιρείας, των εργαζομένων σε
        αυτήν, των συνεργατών της και κάθε επισκέπτη, χρήστη ή εγγεγραμμένου μέλους του Ιστοτόπου.
        <br />
        • Αν και η εταιρία κάνει κάθε προσπάθεια να διατηρήσει τον ιστότοπό της καθαρό από ιούς,
        αδυνατεί να παρέχει οποιαδήποτε εγγύηση ότι δεν θα προσβληθεί από ιούς. Ο κάθε Χρήστης, για
        δική του προστασία, πρέπει να κάνει τις δέουσες ενέργειες για να εξασφαλίσει τα κατάλληλα
        μέτρα ασφάλειας και να χρησιμοποιήσει τα κατάλληλα μέσα ανίχνευσης ιών πριν αποθηκεύσει
        οποιαδήποτε πληροφορία.
        <br />
        • Ρητά επίσης απαγορεύεται η με οποιονδήποτε τεχνικό ή μη τρόπο παρέμβαση οποιουδήποτε
        τρίτου στην λειτουργία του Ιστοτόπου, χωρίς ρητή έγγραφη συναίνεση της Εταιρείας.
        <br />• Ο Αποδέκτης δεσμεύεται ότι θα απέχει από κάθε υβριστική, προσβλητική ή ρατσιστική
        ανάρτηση στον Ιστότοπο και, σε περίπτωση ανάρτησης κριτικής για κάποιον
        Επαγγελματία-Συνεργάτη, αυτή θα πρέπει να είναι γόνιμη και αντικειμενική, εντός των ορίων
        που επιβάλλουν η καλή πίστη και τα χρηστά ήθη και ο Νόμος.
      </p>
      <h4>5. Σύμβαση Παροχής Υπηρεσιών</h4>
      <p>
        Το Vivian Lab είναι μια διαδικτυακή ηλεκτρονική υπηρεσία μεσολάβησης για εγγεγραμμένους
        χρήστες, όπου μπορείτε να παρακολουθήσετε οπτικοακουστικά μέσα (εκπαιδευτικά βίντεο), να
        αναγνώσετε αθρογραφία, να συμμετέχετε σε group webinars, να καταγράψετε τα συμπτώματά σας
        και να δείτε πλάνα διατροφής και ασκήσεων, να λάβετε συμβουλές, καθώς και να συμμετέχετε σε
        hotlines με ειδικούς για εξειδικευμένες ερωτήσεις, να κλείσετε ραντεβού / επικοινωνία μέσω
        συνομιλίας, ανταλλαγής μηνυμάτων, φωνητικής κλήσης, ή βιντεοκλήσης (Συνάντηση) με
        διπλωματούχο Επαγγελματία και να λάβετε πληροφορίες σχετικά με πάσης φύσεως ζητήματα που
        αφορούν την ευημερία. Οι χρήστες μπορούν να συμμετέχουν σε ομαδικές συνεδρίες ή γραμμές
        υποστήριξης με σειρά προτεραιότητας, ανάλογα με τη διαθεσιμότητα κάθε εβδομάδα, χωρίς καμία
        εγγύηση ότι θα εξασφαλίσουν θέση· επιπλέον, το Vivian Lab δεν φέρει ευθύνη για την απουσία
        του παρόχου σε ομαδικές ή ατομικές συνεδρίες και δεν θα παρέχει αποζημίωση σε τέτοιες
        περιπτώσεις.
        <br />
        Περαιτέρω, οι συνεδρίες πραγματοποιούνται μέσω βιντεοκλήσης είτε στην πλατφόρμα Google
        Meetings/Calendly/Jitsi, είτε μέσω της ιστοσελίδας του Vivian Lab κατά την αποκλειστική
        κρίση της ιστοσελίδας. Επιπροσθέτως, ο επαγγελματίας – Πάροχος – χρήστης θα μπορεί να
        πραγματοποιήσει συνεδρίες μέσω chatting και κατόπιν την ολοκλήρωση εκάστης συνεδρίας με τον
        χρήστη -επισκέπτη – μέλος - η συνομιλία θα διαγράφεται από το σύστημα. Η διαδικασία αυτή
        έχει ως σκοπό αφενός μεν να διασφαλιστεί η συνολική λειτουργία και ποιότητα της επικοινωνίας
        της εφαρμογής και αφετέρου η προστασία της πολιτικής απορρήτου στη διαχείριση των συνεδριών
        και της ανταλλαγής πληροφοριών και δεδομένων προσωπικού χαρακτήρα.
        <br />
        Σε καμία περίπτωση η υπηρεσία της online συμβουλευτικής δεν υποκαθιστά την κλινική ή/και
        απαραίτητη εξέταση και τη διενέργεια εργαστηριακών εξετάσεων που είναι απαραίτητα
        προκειμένου να τεθεί μια ασφαλής και τεκμηριωμένη επιστημονική διάγνωση.
        <br />
        Σε καμία περίπτωση το VivianLab δεν φέρει ευθύνη για τις περιεχόμενο των συμβουλών του
        εκάστοτε επαγγελματία – χρήστη προς τον ενδιαφερόμενο, καθώς επίσης για τη διαχείριση των
        πληροφοριών αυτών από τη πλευρά του ενδιαφερόμενου – χρήστη. Σε κάθε περίπτωση τονίζεται ότι
        ο διαδικτυακός τόπος του VivianLab αποτελεί έναν διαμεσολαβητή στην παροχή των υπηρεσιών
        αυτών, παρέχοντας μόνο το μέσο για την παροχή των υπηρεσιών αυτών. Ως εκ τούτου, αυτοί οι
        όροι χρήσης ρυθμίζουν τη σχέση μεταξύ της παρούσας ιστοσελίδας, ως διαμεσολαβητή στην παροχή
        οnline συμβουλευτικής και του τελικού χρήστη της ψηφιακής υπηρεσίας είτε ως ενδιαφερομένου,
        είτε ως επαγγελματία – πάροχου και ΟΧΙ την σχέση των δύο τελευταίων και αποκλειστικός
        υπεύθυνος για τις συμβουλές που δίνονται σε όσους κάνουν χρήση της υπηρεσίας είναι ο
        επαγγελματίας- χρήστης, για το περιεχόμενο την πρακτική και την ορθότητα των πληροφοριών που
        παρέχει, και ο ενδιαφερόμενος - χρήστης, για τη διαχείριση και αξιολόγηση των πληροφοριών
        αυτών. Πριν από τη Συνάντηση, μπορείτε να δώσετε μια γραπτή περιγραφή του θέματός σας, καθώς
        και να ανεβάσετε φωτογραφίες ή/και βίντεο. Όταν ξεκινήσει η Συνάντηση, θα μπορείτε επίσης να
        περιγράψετε το θέμα σας στον Επαγγελματία, ο οποίος μπορεί στη συνέχεια να προτείνει μέτρα
        και γενικά να παράσχει τη συμβουλή του. Μέσω της Υπηρεσίας, μπορείτε επίσης να λάβετε
        γενικές συμβουλές φροντίδας και καθοδήγηση για το θέμα σας, καθώς και πιο λεπτομερείς
        συμβουλές για την περίπτωση που σας απασχολεί.
        <br />
        Σημειώστε ότι οι Βιντεοκλήσεις δεν είναι ολοκληρωμένες παροχές φροντίδας επειδή δεν μπορεί
        να πραγματοποιηθεί φυσική εξέταση, όπου αυτό απαιτείται, αλλά μόνο να σας μεταφερθούν
        συμβουλές και τα μέτρα θεραπείας από τον Επαγγελματία.
        <br />
        Η Vivian Lab δεν φέρει καμία ευθύνη για τις υπηρεσίες που πραγματοποιούνται στο πλαίσιο μιας
        Συνάντησης, για την οποία μόνος υπεύθυνος είναι ο Επαγγελματίας.
        <br />
        Λάβετε υπόψη ότι η Ιστοσελίδα και η Υπηρεσία δεν αντικαθιστούν την παραδοσιακή φροντίδα,
        αλλά αποτελούν συμπλήρωμά της. Αυτό σημαίνει ότι με βάση τις πληροφορίες που έχετε δώσει και
        τα συμπτώματα που περιγράψατε, ο Επαγγελματίας θα σας δώσει συμβουλές για το τι προτείνει ως
        το επόμενο βήμα στην αντιμετώπιση του θέματός σας. Σε κάποιες περιπτώσεις, χωρίς φυσική
        εξέταση, κανένας Επαγγελματίας δεν μπορεί να δώσει οριστική συμβουλή. Εάν πιστεύετε ότι το
        ζήτημά σας σας επιδεινώνεται, εάν εμφανιστούν πρόσθετα συμπτώματα ή παρόμοια, ή εάν
        προκύψουν νέες σχετικές πληροφορίες, κάντε κράτηση για μια νέα Συνάντηση στην Υπηρεσία ή
        επικοινωνήστε με έναν φυσικό Επαγγελματία.
        <br />
        Ενδεικτικά κι όχι περιοριστικά, μέσω της πλατφόρμας Vivian Lab ο Χρήστης μπορεί να κλείσει
        ραντεβού / επικοινωνία της επιλογής του είτε μέσω βιντεοκλήσης (με προτεινόμενη την χρήση
        της εφαρμογής Google meetings ή Jitsi) είτε ραντεβού για συνομιλία με μηνύματα και συνολική
        επικοινωνία μέσω του Vivian Lab. Σε αυτή την δεύτερη περίπτωση η συνομιλία γίνεται απευθείας
        μέσω του Vivian Lab και συγκεκριμένα του εργαλείου / εφαρμογής που λέγεται Postgres. Με τη
        χρήση αυτής της εφαρμογής ο χρήστης πραγματοποιεί συνομιλία δια μέσω μηνυμάτων με τον
        Επαγγελματία – Πάροχο - Χρήστη. Σημειώνεται ότι όταν κλείνει το παράθυρο επικοινωνίας δεν
        αποθηκεύεται η συνομιλία, τα μηνύματα που ανταλλάχτηκαν κατά τη διάρκεια αυτής σβήνονται
        αυτόματα από το σύστημα της εφαρμογής, εκτός και αν επιλέξει ο Χρήστης να αποθηκεύσει την
        επικοινωνία για δική του μελλοντική χρήση στον υπολογιστή του.
        <br />
        Το Vivian Lab δεν προσφέρει γενικής φύσεως ηλεκτρονικές υπηρεσίες αλλά μόνον υπηρεσίες
        μεσολάβησης ανάμεσα στην Γυναίκα – Χρήστη και τον Επαγγελματία. Το Vivian Lab ΔΕΝ ΚΑΘΙΣΤΑΤΑΙ
        ΣΥΜΒΑΛΛΟΜΕΝΟ ΜΕΡΟΣ ΚΑΙ ΔΕΝ ΣΥΜΜΕΤΕΧΕΙ ΚΑΘ' ΟΠΟΙΟΝΔΗΠΟΤΕ ΤΡΟΠΟ ΣΕ ΟΠΟΙΑΔΗΠΟΤΕ ΣΥΜΒΑΣΗ Η΄ ΑΛΛΗ
        ΣΥΜΦΩΝΙΑ ΤΕΛΙΚΩΣ ΣΥΝΑΦΘΕΙ ΜΕΤΑΞΥ ΤΟΥ ΧΡΗΣΤΗ ΚΑΙ ΤΟΥ ΕΠΑΓΓΕΛΜΑΤΙΑ ΑΜΕΣΑ Ή ΕΜΜΕΣΑ ΜΕΣΩ ΤΗΣ
        ΧΡΗΣΗΣ ΤΗΣ ΙΣΤΟΣΕΛΙΔΑΣ Η΄/ΚΑΙ ΤΩΝ ΠΑΡΕΧΟΜΕΝΩΝ ΜΕΣΩ ΑΥΤΗΣ ΥΠΗΡΕΣΙΩΝ.
      </p>
      <h4>6. Οικονομικοί Όροι</h4>
      <p>
        6.1 Ο διαδικτυακός τόπος προσφέρει, μεταξύ άλλων, συνδρομητικές υπηρεσίες με τις οποίες
        επιτρέπει στους συνδρομητές της να έχουν πρόσβαση σε βίντεο, διαδικτυακά βιβλία και λοιπό
        οπτικοακουστικό υλικό μέσω υπολογιστή, ηλεκτρονικής ταμπλέτας, κινητό τηλέφωνο με απαραίτητη
        τη χρήση Διαδικτύου.
        <br />
        Υποστηρίζει είτε την απλή περιήγηση του επισκέπτη είτε την χρήση των συνδρομητικών υπηρεσιών
        του εγγεγραμμένου μέλους. Το κόστος της συνδρομής πρόσβασης στην ιστοσελίδα ορίζεται στα
        45€/τρίμηνο ή 150€/έτος και δεν επιστρέφεται ακόμα και αν ο χρήστης δεν κάνει χρήση των
        υπηρεσιών.
        <br />
        Επίσης ο Χρήστης μπορεί να κάνει δοκιμαστική δωρεάν εγγραφή για ένα τρίμηνο.
        <br />
        Στο παραπάνω κόστος συμπεριλαμβάνονται η παρακολούθηση οπτικοακουστικών μέσων (εκπαιδευτικά
        βίντεο), η ανάγνωση άρθρων, η συμμετοχή σε group webinars, η καταγραφή συμπτωμάτων, πλάνα
        διατροφής και ασκήσεων, συμβουλές, hotlines με ειδικούς για εξειδικευμένες ερωτήσεις, πλην
        των προσωποποιημένων και επιπλέον των ελαχίστων συνεδριών/ραντεβού με ειδικούς υγείας η
        οποία μπορεί να πραγματοποιηθεί είτε δια ζώσης είτε με βιντεοκλήση και έχουν επιπλέον
        χρέωση. Ειδικότερα:
        <br />
        6.2 Συνδρομή
        <br />
        Η συνδρομητική υπηρεσία του Vivian Lab πραγματοποιείται με αυτόματη ανανέωση και απαιτεί από
        τους χρήστες της τη δημιουργία ειδικού προσωπικού λογαριασμού μέσω της ιστοσελίδας μας. H
        συνδρομή σας στο Vivian Lab μπορεί να είναι μηνιαία ή τρίμηνη ή ετήσια με αυτόματη ανανέωση
        και εξαρτάται από την επιλογή σας. Με την εγγραφή σας μας εξουσιοδοτείτε να χρεώνουμε,
        μηνιαίως ή ανά τρίμηνο ή ετήσια, το κόστος συνδρομής που επιλέξατε.
        <br />
        6.3 Χρεώσεις
        <br />
        Περίοδος χρέωσης. Η συνδρομή για την υπηρεσία του διαδικτυακού Ιστότοπου, θα χρεώνεται
        ΑΥΤΟΜΑΤΑ μηνιαία ή ανά τρίμηνο ή ετήσια, μέσω της μεθόδου πληρωμής που έχετε εισάγει κατά
        την εγγραφή σας. Η χρέωση γίνεται κατά την ημερολογιακή ημέρα που συμπίπτει με τη
        συγκεκριμένη ημερομηνία έναρξης της συνδρομής σας, δηλαδή την ΗΜΕΡΟΜΗΝΙΑ ΑΝΑΝΕΩΣΗΣ
        ΣΥΝΔΡΟΜΗΣ. Σε ορισμένες περιπτώσεις, το χρονοδιάγραμμα χρέωσής σας ενδέχεται να διαφέρει,
        για παράδειγμα, εάν η μέθοδος πληρωμής σας δεν έχει προχωρήσει με επιτυχία ή εάν η συνδρομή
        πληρωμής ξεκίνησε σε ημέρα που δεν περιέχεται σε ένα συγκεκριμένο μήνα. Μπορείτε να δείτε
        την επόμενη ημερομηνία ανανέωσης συνδρομής στον ιστότοπό μας, κάνοντας κλικ στο μενού
        «ΛΟΓΑΡΙΑΣΜΟΣ», χρειάζεται να είστε συνδεδεμένοι στο προφίλ σας.
        <br />
        6.4 Τρόποι πληρωμής
        <br />
        Η πληρωμή της συνδρομή σας γίνεται με τη χρήση πιστωτικής ή χρεωστικής κάρτας. Κατά την
        εγγραφή σας στην υπηρεσία θα σας ζητηθεί να επιλέξετε έναν από τους υποδεικνυόμενους
        έγκυρους τρόπους πληρωμής. Εάν μια πληρωμή δεν διευθετηθεί με επιτυχία, λόγω λήξης ισχύος
        της πιστωτικής ή της χρεωστικής σας κάρτας, είτε λόγω ανεπαρκούς υπολοίπου στο λογαριασμό
        σας ή για οποιονδήποτε άλλο λόγο και δεν προβείτε έγκαιρα στην αλλαγή του τρόπου πληρωμής ή
        στην ακύρωση του λογαριασμού σας, η Εταιρεία διατηρεί το δικαίωμα να αναστείλει την πρόσβασή
        σας στην υπηρεσία, μέχρι να λάβει χώρα έγκυρη πληρωμή. Όταν ενημερώσετε τον επιλεγμένο τρόπο
        πληρωμής σας ή ανανεώσετε το χρηματικό διαθέσιμο στον ήδη υπάρχοντα τρόπο πληρωμής, μας
        παρέχετε ταυτόχρονα την εξουσιοδότηση να συνεχίσουμε να τον χρεώνουμε με το κόστος της
        συμφωνηθείσας συνδρομής -εφόσον δεν έχετε ακολουθήσει την διαδικασία ακύρωσης συνδρομής και
        διατηρείτε δικαίωμα πρόσβασης στην υπηρεσία. Αυτό μπορεί να συνεπάγεται αλλαγή στις
        ημερομηνίες πληρωμής των λογαριασμών σας. Οι τρόποι πληρωμής μπορεί να μεταβάλλονται ή να
        εμπλουτίζονται ανά τακτά χρονικά διαστήματα. Στην περίπτωση αυτή θα ενημερωθείτε έγκαιρα με
        κάποιον από τους συμφωνημένους τρόπους επικοινωνίας σας με την Εταιρεία.
        <br />
        6.5 Ακύρωση
        <br />
        Μπορείτε να ακυρώσετε τη συνδρομή σας οποιαδήποτε στιγμή επιθυμείτε. Από την στιγμή που θα
        ακυρώσετε την συνδρομή σας θα έχετε πρόσβαση στην υπηρεσία μέχρι το τέλος της περιόδου
        χρήσης της υπηρεσίας. Δεν υπάρχει δυνατότητα επιστροφής χρηματικών ποσών που χρεώθηκαν για
        περιόδους χρήσης της υπηρεσίας που δεν χρησιμοποιήθηκαν από επιλογή σας, χωρίς να έχει
        τηρηθεί η προβλεπόμενη διαδικασία ακύρωσης της συνδρομής σας. Μετά την διεκπεραίωση της
        πληρωμής (είτε για αγορά είτε για ανανέωση της συνδρομής) αρχίζει αυτόματα η δυνατότητα
        προβολής / μετάδοσης του υλικού οπότε και χάνεται κάθε δικαίωμα για υπαναχώρηση. Υπαναχώρηση
        και επιστροφή χρημάτων πραγματοποιείτε μόνον για την επαναλαμβανόμενη χρέωση/ ανανέωση, σε
        περίπτωση που υπάρξει αίτημα μέσω ηλεκτρονικού ταχυδρομείου εντός 24 ωρών από την ώρα της
        ανανέωσης. Για να συνεχίσετε με την ακύρωση της συνδρομής πρέπει να μεταβείτε στην
        ιστοσελίδα και αφού συνδεθείτε στο λογαριασμό σας να μεταβείτε στο μενού «ΛΟΓΑΡΙΑΣΜΟΣ» όπου
        υπάρχει και το κουμπί ακύρωσης συνδρομής. Αφού ακολουθήσετε τον ενδεδειγμένο τρόπο ακύρωσης
        και μετά το πέρας της ημερομηνίας λήξης της συνδρομής σας, παύει κάθε δικαίωμα του
        διαδικτυακού τόπου για χρέωση της χρεωστικής/πιστωτικής σας κάρτα. Σε περίπτωση εσφαλμένης
        χρέωσης αφού έχετε ακολουθήσει την διαδικασία ακύρωσης, γίνεται επιστροφή χρημάτων.
        <br />
        6.6 Αλλαγές στην τιμολόγηση.
        <br />
        To Vivian Lab διατηρεί το δικαίωμα να μεταβάλλει τις τιμές χρέωσης των συνδρομών ανά τακτά
        χρονικά διαστήματα. Οποιαδήποτε τέτοια αλλαγή δεν θα επηρεάσει τις χρεώσεις σας μέχρι τη
        λήξη της συμφωνημένης διάρκειας της υπηρεσίας και δε θα γίνεται χωρίς την έγκαιρη ενημέρωσή
        σας. Σε περίπτωση κατά την οποία δεν συμφωνείτε με τις νέες χρεώσεις ακολουθείτε τη
        διαδικασία Ακύρωσης, που προβλέπεται στο άρθρο.
        <br />
        6.7 Επιπλέον Υπηρεσίες
        <br />
        Το εγγεγραμμένο μέλος μπορεί να πραγματοποιήσει επιπλέον συνεδρία/ραντεβού με επαγγελματία
        της επιλογής του. Το κόστος ανά συνεδρία/ραντεβού ορίζεται αποκλειστικά από τον
        επαγγελματία.
        <br />
        Ειδικά για συνεδρία μέσω βιντεοκλήσης: Έως και 24 ώρες πριν το προγραμματισμένο ραντεβού,
        μπορείτε να ακυρώσετε τη Συνάντηση / Επικοινωνία / Βιντεοκλήση και να λάβετε πλήρη επιστροφή
        των χρημάτων που καταβάλατε. Λάβετε υπ' όψη ότι, λόγω των σχετικών διαδικασιών, μπορεί να
        χρειαστούν έως και 14 ημέρες μέχρι να σας επιστραφούν τα χρήματά σας στον λογαριασμό σας.
        Για βιντεοκλήση που ακυρώνεται εντός διαστήματος έως και 24 ωρών πριν από τη διεξαγωγή της,
        προβλέπονται έξοδα ακυρώσεως και δεν δικαιούστε επιστροφή χρημάτων.
        <br />
        6.8 Σε περίπτωση απώλειας σύνδεσης κατά τη διάρκεια της Συνάντησης, θα καταβάλλουμε κάθε
        προσπάθεια για την επανάκτησή της. Σε περίπτωση που αυτό δεν καταστεί δυνατό και η σύνδεση
        δεν επανέλθει, έχετε το δικαίωμα για δωρεάν επανάληψή της στο αμέσως επόμενο διαθέσιμο
        ραντεβού.
        <br />
        6.9 Ενδεικτικά, οι τρόποι πληρωμής που μπορείτε να ακολουθήσετε είναι οι εξής:
        <br />
        ~ Οι πληρωμές πραγματοποιούνται μέσω της εφαρμογής «stripe», όπου βεβαίως ο χρήστης της
        εφαρμογής οργανώσει τον λογαριασμό του για τη λειτουργία της, αποδέχεται αυτοδικαίως και
        αυτομάτως τους Όρους και Προϋποθέσεις του και κατόπιν ακολουθεί τα βήματα που απαιτεί η
        εφαρμογή ώστε να ολοκληρωθεί η διαδικασία και να πραγματοποιούνται οι πάσης φύσεως
        οικονομικές συναλλαγές / πληρωμές. Το Vivian Lab δε συμμετέχει κατ' οιοδήποτε τρόπο σε αυτήν
        τη διαδικασία κι επομένως δε φέρει καμία ευθύνη σχετικά με πάσης φύσεως οικονομική συναλλαγή
        / πληρωμή πραγματοποιηθεί ή όχι. Αναλυτικά, μπορείτε να διαβάσετε http://vivianlab.com/faq
        <br />
        ~ Ο χρήστης - επισκέπτης ή μέλος - πρέπει να προσθέσει την πιστωτική του κάρτα για να
        κλείσει ραντεβού. Η πιστωτική κάρτα δεν χρεώνεται, μόνο προ εγκρίνεται και το ποσό είναι
        δεσμευμένο για την διενέργεια της επικοινωνίας
        <br />
        ~ Εάν η επικοινωνία πραγματοποιηθεί με οιαδήποτε τρόπο όπως ορίστηκε, ο Επαγγελματίας -
        Πάροχος πρέπει να κάνει κλικ σε μία επιλογή που θα παραλάβει μέσω email ή στην ηλεκτρονική
        πλατφόρμα πληρωμών και να επιβεβαιώσει τη συναλλαγή εντός 7 ημερών, προκειμένου να
        πραγματοποιηθεί η πληρωμή. ~ Μόλις επιλέξει την επιλογή χρέωσης / αποδοχής στο σχετικό
        πεδίο, η πιστωτική κάρτα του χρήστη χρεώνεται εντός 24 ωρών και ο Επαγγελματίας – Πάροχος -
        Χρήστης λαμβάνει τα χρήματά του στον τραπεζικό λογαριασμό που έδωσε μέσω της εφαρμογής
        'stripe' εντός 7 ημερών. ~ Ο επαγγελματίας – Πάροχος – χρήστης είναι υπεύθυνος να στείλει
        στον χρήστη τη σχετική απόδειξη / παραστατικό για τη συνεδρία / υπηρεσία που
        πραγματοποιήθηκε με e-mail απευθείας στον χρήστη επισκέπτη - μέλος στον οποίο προσέφερε τις
        υπηρεσίες του σύμφωνα με το προσωπικό φορολογικό και λογιστικό καθεστώς στο οποίο υπάγεται.
        <br />
        ~ Εάν ο επαγγελματίας – Πάροχος – Χρήστης δεν επιβεβαιώσει την πληρωμή εντός 7 ημερών, δεν
        θα λάβει ποτέ την πληρωμή παρόλο που πραγματοποιήθηκε η συνεδρία και τα χρήματα θα
        επιστρέψουν στην πιστωτική κάρτα του χρήστη εντός 7 ημερών.
        <br />
        ~ Επιπροσθέτως των ανωτέρω, προς επιβεβαίωση της υπηρεσίας που προσφέρθηκε μέσω του Vivian
        Lab το αυτοματοποιημένο σύστημα του Vivian Lab εκδίδει απόδειξη παροχής της υπηρεσίας για
        χρήση από την εφαρμογή / πλατφόρμα. Σε καμία περίπτωση η απόδειξη του αυτοματοποιημένου
        συστήματος δεν αντικαθιστά ή υποκαθιστά το φορολογικό παραστατικό που επιλέγει ή που
        υποχρεούται να εκδώσει ο επαγγελματίας – Πάροχος – χρήστης για δικούς του φορολογικούς και
        λογιστικούς λόγους και προωθεί στον χρήστη που έλαβε την υπηρεσία ως ανωτέρω.
        <br />
        ~ Το Vivian Lab έχει το δικαίωμα να επιστρέψει στον χρήστη τα χρήματα εάν η εταιρεία
        πιστεύει ότι η διαδικασία ή η υπηρεσία δεν εκπληρώθηκε ή ολοκληρώθηκε όπως προβλέπεται. Σε
        αυτήν την περίπτωση, ακόμα κι αν ο Επαγγελματίας έχει ήδη λάβει τα χρήματά του στον
        τραπεζικό λογαριασμό μέσω της ηλεκτρονικής εφαρμογής, το Vivian Lab μπορεί να τα αποσύρει
        από τον λογαριασμό του Επαγγελματία και να τα επιστρέψει στον χρήστη.
        <br />
        ~ Όταν ο επαγγελματίας – Πάροχος – χρήστης προσθέτει τα διαπιστευτήριά και τα στοιχεία του
        για τη διαδικασία, αποδέχεται απευθείας τους Όρους και Προϋποθέσεις αυτής και επομένως το
        Vivian Lab δεν είναι υπεύθυνη για τις πληρωμές και τη συνολική διαδικασία.
        <br />
        ~ Εάν υπήρχε κάποιο τεχνικό πρόβλημα και ο Επαγγελματίας – Πάροχος – Χρήστης ή ο χρήστης
        επισκέπτης - μέλος δεν μπορούσαν να συμμετάσχουν στη συνάντηση επειδή δεν ήταν εφικτή η
        διαδικτυακή σύνδεση ή επειδή η πλατφόρμα δεν λειτουργούσε, τότε το Vivian Lab μπορεί να
        ακυρώσει τη συνεδρία δεν θα υπάρξει χρέωση και επομένως ο επαγγελματίας – Πάροχος - χρήστης
        δεν δικαιούται να λάβει πληρωμή, Αυτό παραμένει στη διακριτική ευχέρεια του Vivian Lab.
        <br />
        ~ Το Vivian Lab έχει πλήρη διακριτική ευχέρεια και εξουσία να ακυρώνει πληρωμές, να
        επιστρέφει χρήματα στους χρήστες, να μην πληρώνει τον επαγγελματία – Πάροχο – χρήστη εάν
        μέρος ή το σύνολο της διαδικασίας δεν ακολουθήθηκε. Το Vivian lab δεν χρειάζεται να δώσει
        εξηγήσεις σε οιαδήποτε σχετιζόμενο μέρος σχετικά με αυτή την απόφαση αυτή. Ειδικότερα δε, σε
        περίπτωση που ένας επαγγελματίας – Πάροχος – χρήστης ακυρώσει τέσσερα (4) προγραμματισμένα
        μέσω του Vivian Lab ραντεβού προς παροχή υπηρεσιών παρέχεται αυτοδικαίως το δικαίωμα στο
        Vivian Lab να διακόψει την πάσης φύσεως συνεργασία με τον επαγγελματία – Πάροχο – χρήστη και
        να αφαιρέσει την καταχώρησή του από τις επιλογές της πλατφόρμας.
        <br />
        6.10 Όλες οι πληρωμές, που πραγματοποιούνται με χρήση πιστωτικής, χρεωστικής και
        προπληρωμένης κάρτας, διεκπεραιώνονται μέσω της ψηφιακής πλατφόρμας ηλεκτρονικών πληρωμών
        'stripe' η οποία πληροί τις προδιαγραφές που επιβάλλει τα πρότυπα για την ασφάλεια δεδομένων
        καρτών πληρωμών. Παράλληλα, κατά τις ηλεκτρονικές πληρωμές με κάρτα, εφαρμόζεται ισχυρή
        ταυτοποίηση πελάτη κατά το πρωτόκολλο 3-D secure, σε συμμόρφωση με την Ευρωπαϊκή Οδηγία
        2015/2366 (PSD2) και τις απαιτήσεις για ισχυρή ταυτοποίηση πελάτη. (Strong Customer
        Authentication).
        <br />
      </p>
      <h4>7. Αποποίηση Ευθύνης</h4>
      <p>
        Ο ΣΚΟΠΟΣ ΤΗΣ ΣΕΛΙΔΑΣ ΕΙΝΑΙ ΚΑΘΑΡΑ ΕΝΗΜΕΡΩΤΙΚΟΣ ΚΑΙ ΔΙΑΜΕΣΟΛΑΒΗΤΙΚΟΣ ΚΑΙ ΔΕΝ ΥΠΟΚΑΘΙΣΤΑ ΤΙΣ
        ΙΑΤΡΙΚΕΣ ΣΥΜΒΟΥΛΕΣ ΟΥΤΕ ΠΑΡΕΧΕΙ ΤΕΤΟΙΕΣ Το Vivian Lab είναι αποκλειστικά υπεύθυνη για τη
        λειτουργία της Ιστοσελίδας, συμπεριλαμβανομένης της παροχής της Συνάντησης μεταξύ εσάς και
        του Επαγγελματία.
        <br />
        Οι Επαγγελματίες μας είναι ανεξάρτητοι επαγγελματίες και φέρουν εκείνοι την ευθύνη για τις
        συμβολές και τις οδηγίες τους, οι οποίες δίδονται είτε μέσω του εκπαιδευτικού υλικού της
        ιστοσελίδας (άρθρα, βίντεο κλπ) είτε σε συνεδρία (μέσω βιντεοκλήσης ή/και δια ζώσης). Η
        VivianLab δεν είναι υπεύθυνη για τη φροντίδα, την ενημέρωση ή τη σύσταση του Επαγγελματία,
        οι οποίες είτε παρέχονται στο πλαίσιο της Συνάντησης είτε γραπτώς μέσω της Ιστοσελίδας. Το
        Vivian Lab δεν ευθύνεται για οιαδήποτε αμέλεια, εφόσον την ευθύνη την έχει ο Επαγγελματίας.
        <br />
        Το Vivian Lab απλώς διευκολύνει τη σύναψη των εκάστοτε συμφωνιών μεταξύ Πελάτη και
        Επαγγελματία αποκλειστικά με την παροχή διαδικτυακού χώρου όπου οι συμβαλλόμενοι έρχονται σε
        επαφή ώστε στη συνέχεια να συμβληθούν στον χρόνο που εκείνοι θα επιλέξουν. Επομένως,
        αναγνωρίζεται ότι οποιαδήποτε ειδικότερη συμφωνία συνάψει ο Χρήστης - Πελάτης με τον
        Επαγγελματία δεν δεσμεύει με οποιοδήποτε τρόπο το Vivian Lab και ότι το Vivian Lab δεν φέρει
        καμία ευθύνη για τυχόν αξιώσεις εξαιτίας οποιασδήποτε διαφοράς, πράξης ή παράλειψης σε σχέση
        με την σύναψη, την εγκυρότητα ή την εκτέλεση οποιασδήποτε συμφωνίας μεταξύ Πελάτη και
        Επαγγελματία.
        <br />
        Ενδεικτικά και όχι περιοριστικά το Vivian Lab δεν φέρει καμία απολύτως ευθύνη και
        αποποιείται οποιασδήποτε αξιώσεως ως προς τα εξής:
        <br />
        α) Εκτέλεση της Εργασίας από τον Επαγγελματία: το Vivian Lab δεν φέρει καμία ευθύνη για την
        ορθή εκτέλεση της Εργασίας ή/και για την ποιότητα ή καταλληλότητα των παρεχομένων εκ μέρους
        του υπηρεσιών και για οποιαδήποτε σχετική αξίωση, όπως ενδεικτικά και όχι περιοριστικά για
        τυχόν αξιώσεις από ζημίες που προκαλούνται σε άτομα, αντικείμενα, περιουσιακά και άλλα
        στοιχεία ή από άλλες άμεσες, έμμεσες ή παρεπόμενες ζημίες που ο Πελάτης μπορεί να υποστεί
        από τον Επαγγελματία.
        <br />
        β) Τήρηση της Νομοθεσίας: Ο Πελάτης και ο Επαγγελματίας οφείλουν να τηρούν την εκάστοτε
        ισχύουσα νομοθεσία και τις αρχές που επιβάλλουν η καλή πίστη και τα συναλλακτικά ήθη και να
        μεριμνούν ώστε το περιεχόμενο της μεταξύ τους συμφωνίας ή σύμβασης αλλά και κάθε πράξη ή
        ενέργειά τους να μην αντίκειται στα ανωτέρω.
        <br />
        γ) Το περιεχόμενο των υπηρεσιών υγείας και φροντίδας από τους παρόχους, συμπεριλαμβανομένου
        του περιεχομένου των οπτικοακουστικών μέσων (βίντεο) και άρθρων.
        <br />
        Οι υπηρεσίες που παρέχονται σε αυτόν τον ιστότοπο παρέχονται «ως έχουν», χωρίς καμία
        εγγύηση, ρητή ή σιωπηρή, συμπεριλαμβανομένων, χωρίς περιορισμό, εγγυήσεων επιτυχίας,
        καταλληλόλητας ή μη για συγκεκριμένο σκοπό καθώς και μη παραβίασης πάσης φύσεως νομοθεσίας
        από πλευράς του χρήστη και του προσφέροντος την εκάστοτε υπηρεσία. Το συγκεκριμένα δεν
        παρέχει καμία εγγύηση ή δήλωση σχετικά με την ακρίβεια ή την πληρότητα οποιασδήποτε δήλωσης,
        προώθησης, επικοινωνίας και τέλος υπηρεσίας που επιλέχθηκε από τον χρήστη και παρείχε ο
        τρίτος συνεργάτης.
        <br />
        Το Vivian Lab δύναται να προσθέτει, αλλάζει, βελτιώνει ή ενημερώνει περιοδικά το περιεχόμενο
        σε αυτόν τον ιστότοπο χωρίς προειδοποίηση. Σε καμία περίπτωση το Vivian Lab δεν θα είναι
        υπεύθυνη για οποιαδήποτε απώλεια, ζημία, ευθύνη ή δαπάνη που προκλήθηκε ή τυχόν υπέστη ή που
        ισχυρίζεται ότι προήλθε ο οιοσδήποτε από τη χρήση αυτού του ιστότοπου, συμπεριλαμβανομένου,
        χωρίς περιορισμό, οποιουδήποτε σφάλματος, λάθους, παράλειψης, διακοπής ή καθυστέρησης. Ως
        ανωτέρω η χρήση αυτής της τοποθεσίας γίνεται με αποκλειστική ευθύνη του Χρήστη και του
        πάροχου υπηρεσιών αντίστοιχα. Σε καμία περίπτωση, συμπεριλαμβανομένης ενδεικτικά της
        αμέλειας, το Vivian Lab δεν δύναται να θεωρηθεί υπεύθυνη για οποιεσδήποτε άμεσες, έμμεσες,
        τυχαίες, ειδικές ή αποθετικές ζημίες, ακόμη και αν ενημερωθεί για την πιθανότητα τέτοιων
        ζημιών.
        <br />
        Ο εκάστοτε επισκέπτης και χρήστης του ιστότοπου αναγνωρίζει συγκεκριμένα και συμφωνεί ότι το
        Vivian Lab δεν ευθύνεται για οποιαδήποτε συμπεριφορά οποιουδήποτε έτερου χρήστη ή συνεργάτη.
        <br />
        Αυτός ο ιστότοπος μπορεί να περιέχει εκτός από τις προτεινόμενες υπηρεσίες και συμβουλές,
        απόψεις, προτάσεις, πληροφορίες και δηλώσεις διαφόρων παρόχων πληροφοριών και υπηρεσιών. Η
        ευθύνη για τις απόψεις που πιθανόν να εκφράζονται κατ' οιοδήποτε τρόπο ακόμη και σε
        υπογεγραμμένα άρθρα, μελέτες και άλλες συνεισφορές στον Ιστότοπο βαρύνουν αποκλειστικά τους
        συντάκτες τους. Το Vivian Lab δεν αντιπροσωπεύει ούτε υποστηρίζει την ακρίβεια ή την
        αξιοπιστία οποιασδήποτε υπηρεσίας, συμβουλής, γνώμης, δήλωσης ή άλλης πληροφορίας που
        παρέχεται από οποιονδήποτε πάροχο πληροφοριών, οποιονδήποτε χρήστη αυτού του ιστότοπου ή
        οποιοδήποτε άλλο πρόσωπο ή οντότητα, και ούτε αυτή η υπηρεσία, συμβουλή, αυτές οι απόψεις ή
        δηλώσεις ή άλλες πληροφορίες αντιπροσωπεύουν αναγκαστικά τις απόψεις του Vivian Lab.
        <br />
        Η εξάρτηση και περαιτέρω χρήση από οποιαδήποτε τέτοια υπηρεσία, συμβουλή, γνώμη, δήλωση ή
        άλλη πληροφορία γίνεται επίσης με ευθύνη του χρήστη. Επομένως, το Vivian Lab αλλά και
        οποιοσδήποτε από τους αντίστοιχους συνεργαζόμενους παρόχους υπηρεσιών, αντιπροσώπους,
        εργαζόμενους, πάροχους πληροφοριών ή γενικότερα πάροχους περιεχομένου, θα είναι υπεύθυνοι
        έναντι οποιουδήποτε χρήστη ή οποιουδήποτε άλλου για οποιαδήποτε ανακρίβεια, σφάλμα,
        παράλειψη, διακοπή, διαγραφή, ελάττωμα, τροποποίηση ή χρήση οποιουδήποτε περιεχομένου στο
        παρόν, ή για την επικαιρότητα ή την πληρότητά του, ούτε φέρουν ευθύνη για τυχόν αστοχία
        απόδοσης, ιούς υπολογιστή ή αστοχία γραμμής επικοινωνίας, ανεξαρτήτως αιτίας, ή για τυχόν
        ζημίες που προκύπτουν από αυτά.
        <br />
        Αυτός ο ιστότοπος περιέχει συνδέσμους και αναφορές σε ιστότοπους και υπηρεσίες τρίτων. Οι
        συνδεδεμένοι ιστότοποι δεν βρίσκονται υπό τον έλεγχο του Vivian Lab καθώς και το Vivian Lab
        δεν ευθύνεται για το περιεχόμενο οποιουδήποτε συνδεδεμένου ιστότοπου ή οποιουδήποτε
        συνδέσμου που περιέχεται σε συνδεδεμένο ιστότοπο. Το Vivian Lab παρέχει αυτούς τους
        συνδέσμους μόνο ως διευκόλυνση και περαιτέρω αναφορά και πιθανή συνεργασία και η συμπερίληψη
        ενός συνδέσμου ή αναφοράς δεν συνεπάγεται την έγκριση του συνδεδεμένου ιστότοπου από την
        εταιρεία.
        <br />
        Η Εταιρεία διατηρεί το δικαίωμα να αναστέλλει ή και να απαγορεύει την χρήση των υπηρεσιών
        της σε κάθε χρήστη ή Επαγγελματία που δεν συμμορφώνεται με τις διατάξεις του παρόντος, ο
        οποίος και δεν δύναται να εγγραφεί στις Υπηρεσίες του Ιστοτόπου εκ νέου, χωρίς την
        προηγούμενη έγγραφη συναίνεση της Εταιρείας.
        <br />Η Εταιρία επιφυλάσσεται να χρησιμοποιήσει κάθε νόμιμο δικαίωμά της και να αξιώσει
        αποζημιώσεις από τους συμβαλλόμενους συνεργάτες της, σε περίπτωση εκ μέρους τους παραβίασης
        των Όρων Χρήσης η των δικαιωμάτων συνεργαζόμενων εταιριών ή/και τρίτων.
      </p>
      <h4>8. Βοηθός AI</h4>
      <p>
        Η ιστοσελίδα έχει ενσωματώσει εργαλείο τεχνίτης νοημοσύνης το οποίο ακόμη βρίσκεται υπό
        ανάπτυξη. Το εργαλείο αναζητά, στο υλικό της ιστοσελίδας, απαντήσεις στα ερωτήματα που του
        θέτετε και απαντά με βάση αυτά. Η επεξεργασία των δεδομένων που εισάγονται σε αυτό,
        ακολουθεί τη γενική πολιτική Προστασίας Δεδομένων της Ιστοσελίδας. ΣΗΜΕΙΩΣΗ: Ο ΒΟΗΘΟΣ ΣΕ
        ΚΑΜΙΑ ΠΕΡΙΠΤΩΣΗ ΔΕΝ ΠΑΡΕΧΕΙ ΙΑΤΡΙΚΕΣ ΣΥΜΒΟΥΛΕΣ ΜΕ ΟΙΟΝΔΗΠΟΤΕ ΤΡΟΠΟ.
      </p>
      <h4>9. Τελικές Διατάξεις</h4>
      <p>
        9.1 Οι εικόνες, τα λογότυπα/σήματα, τα γραφικά, οι φωτογραφίες και τα κείμενα καθώς και
        οποιοδήποτε άλλο στοιχείο δυνάμενο να αποτελέσει αντικείμενο δικαιωμάτων διανοητικής
        ιδιοκτησίας, ευρισκόμενα στην Ιστοσελίδα, συνιστούν πνευματική ιδιοκτησία της Εταιρείας και
        προστατεύονται βάσει των σχετικών διατάξεων της Ελληνικής και Ενωσιακής νομοθεσίας.
        Συνακόλουθα, απαγορεύεται οποιαδήποτε αντιγραφή, αναλογική/ψηφιακή εγγραφή και μηχανική
        αναπαραγωγή, downloading, μεταποίηση, μεταπώληση και εν γένει εμπορική εκμετάλλευση του
        περιεχομένου της Ιστοσελίδας.
        <br />
        9.2 Σε καμία περίπτωση η Ιστοσελίδα και η ιδιοκτησία της, δεν ευθύνεται για οποιαδήποτε
        ζημία, θετική ή αποθετική, που τυχόν θα υποστούν οι χρήστες συνεπεία της χρήσης των
        παρεχόμενων υπηρεσιών, οφειλόμενη άμεσα ή έμμεσα σε ενέργειες ή παραλείψεις τρίτων.
        <br />
        9.3 Το παρόν αποτελεί ένα σύνολο τόσο με το Προσάρτημα Προσωπικών Δεδομένων, Πνευματικής και
        Βιομηχανικής Ιδιοκτησίας και της Πολιτικής Απορρήτου, ενώ υπερισχύει και συμπληρώνει κάθε
        ειδικότερη συμφωνία με τους χρήστες των Υπηρεσιών του Ιστοτόπου ή τους Επαγγελματίες-Χρήστες
        αυτού.
        <br />
        9.4 Οι Όροι Χρήσης της Ιστοσελίδας ενδέχεται να μεταβληθούν κατά την αποκλειστική κρίση της.
        Σας ενθαρρύνουμε να ανατρέχετε, ανά τακτά διαστήματα, στην παρούσα, η οποία θα είναι
        προσβάσιμη πάντοτε για εσάς, προκειμένου να ενημερώνεστε για τυχόν αλλαγές.
        <br />
        9.5 Αν κάποια από τις ανωτέρω διατάξεις κριθεί άκυρη ή ακυρώσιμη δεν θίγει σε καμία
        περίπτωση την ισχύ των λοιπών διατάξεων που θα εξακολουθούν σε κάθε περίπτωση να παραμένουν
        σε ισχύ.
        <br />
        9.6 Η Εταιρεία επιφυλάσσεται παντός νόμιμου δικαιώματός της για κάθε δικαίωμά του που
        θίγεται αναφορικά με την παρούσα, τους όρους και τις προϋποθέσεις επίσκεψης και χρήσης αυτού
        και τα δικαιώματα πνευματικής και διανοητικής του ιδιοκτησίας.
        <br />
        9.7 Η Εταιρεία έχει δικαίωμα, στα πλαίσια της εμπορικής της πολιτικής να προχωρά σε
        προωθητικές ενέργειες, συμπεριλαμβανομένης μεταξύ άλλων και της χορήγησης διατακτικών. Σε
        περίπτωση όπου χρήστης, χρησιμοποιήσει διατακτική για τη χρήση υπηρεσιών της ιστοσελίδας, ο
        ειδικός υγείας (πάροχος) θα αμείβεται από την εταιρεία και όχι από τον χρήστη. Η Vivian Lab
        δεν ευθύνεται για τυχόν καθυστέρηση στην χρήση των διατακτικών, εφόσον τα ραντεβού με τους
        παρόχους θα πραγματοποιούνται σύμφωνα με τη διαθεσιμότητα αυτών.
        <br />
        9.8 Σε κάθε περίπτωση, για οποιαδήποτε διαφορά προκύψει μεταξύ της Ιστοσελίδα και των
        επισκεπτών ή των μελών της Ιστοσελίδας, αρμόδια είναι τα Δικαστήρια της Αθήνας και
        εφαρμοστέο το Ελληνικό Δίκαιο.
      </p>
      <p>
        ΣΗΜΑΝΤΙΚΗ ΣΗΜΕΙΩΣΗ
        <br />
        Παρακαλείστε όπως διαβάσετε προσεκτικά τους παραπάνω Όρους Χρήσης προτού αρχίσετε να
        χρησιμοποιείτε τις Υπηρεσίες. ΜΕ ΠΡΟΣΒΑΣΗ, ΠΕΡΙΗΓΗΣΗ Ή ΧΡΗΣΙΜΟΠΟΙΩΝΤΑΣ ΤΙΣ ΥΠΗΡΕΣΙΕΣ
        (ΣΥΜΠΕΡΙΛΑΜΒΑΝΟΝΤΑΣ ΤΗΝ ΥΠΟΒΟΛΗ ΤΩΝ ΠΛΗΡΟΦΟΡΙΩΝ ΣΑΣ ΣΤΗΝ ΙΣΤΟΣΕΛΙΔΑ), ΑΝΑΔΕΧΕΤΕ ΟΤΙ
        ΔΙΑΒΑΣΑΤΕ, ΚΑΤΑΝΟΗΣΑΤΕ ΚΑΙ ΣΥΜΦΩΝΗΣΑΤΕ ΝΑ ΔΕΣΜΕΥΘΕΙΤΕ ΑΠΟ ΑΥΤΑ ΑΝΑΦΟΡΑ), ΚΑΙ ΓΙΑ ΣΥΜΜΟΡΦΩΣΗ
        ΜΕ ΟΛΟΥΣ ΤΟΥΣ ΙΣΧΥΟΥΣ ΝΟΜΟΥΣ ΚΑΙ ΚΑΝΟΝΙΣΜΟΥΣ. Συμφωνείτε ότι οι Όροι Χρήσης, σε συνδυασμό με
        την πράξη χρήσης των Υπηρεσιών, έχουν την ίδια νομική ισχύ και ισχύ με μια γραπτή σύμβαση με
        γραπτή υπογραφή σας και πληρούν τυχόν νόμους που απαιτούν γραπτή ή υπογραφή. Συμφωνείτε
        επίσης ότι δεν θα αμφισβητήσετε την εγκυρότητα, την εκτελεστότητα ή το παραδεκτό των Όρων
        Χρήσης με την αιτιολογία ότι διαβιβάστηκαν ηλεκτρονικά ή εξουσιοδοτήθηκαν από την εταιρεία
        Vivian Lab.
        <br />
        Συμφωνείτε επίσης ότι δεν θα αμφισβητήσετε την εγκυρότητα, την εκτελεστότητα ή το παραδεκτό
        των Όρων Χρήσης με την αιτιολογία ότι διαβιβάστηκαν ηλεκτρονικά ή εξουσιοδοτήθηκαν από την
        εταιρεία Vivian Lab. Συμφωνείτε να χρησιμοποιείτε τις υπηρεσίες του διαδικτυακού τόπου
        www.Vivianlab.com συμπεριλαμβανομένων όλων των λειτουργιών που σχετίζονται με αυτήν, σύμφωνα
        με όλους τους ισχύοντες νόμους, κανονισμούς ή άλλους περιορισμούς στη χρήση της υπηρεσίας ή
        του περιεχομένου της. Συμφωνείτε να μην αρχειοθετήσετε, μεταφορτώσετε, αναπαραγάγετε,
        μεταδώσετε, προβάλλετε, εκτελέσετε, δημοσιεύσετε, εκχωρήσετε άδεια χρήσης, δημιουργήσετε
        παράγωγα έργα, προσφέρετε προς πώληση ή χρησιμοποιήσετε το περιεχόμενο που λαμβάνετε μέσω
        των υπηρεσιών του διαδικτυακού τόπου. Συμφωνείτε να μην παρακάμψετε, διαγράψετε, αλλάξετε,
        απενεργοποιήσετε, υποβαθμίσετε ή αποτρέψετε οποιαδήποτε προστασία περιεχομένου του
        διαδικτυακού τόπου. Συμφωνείτε να μην χρησιμοποιήσετε οποιαδήποτε αυτοματοποιημένα μέσα για
        να έχετε πρόσβαση στον διαδικτυακό τόπο. Συμφωνείτε να μην αναδιαμορφώσετε, αναστρέψετε ή
        αποσυναρμολογήσετε οποιοδήποτε λογισμικό ή άλλα προϊόντα ή διαδικασίες που είναι προσβάσιμες
        μέσω του διαδικτυακού τόπου. Συμφωνείτε να μην εισάγετε οποιοδήποτε κωδικό ή προϊόν ή
        χειριστείτε το περιεχόμενο του διαδικτυακού τόπου με οποιονδήποτε τρόπο ή να χρησιμοποιήσετε
        οποιαδήποτε μέθοδο εξόρυξης δεδομένων, συλλογής δεδομένων ή εξαγωγής. Επιπλέον, συμφωνείτε
        να μην ανεβάζετε, δημοσιεύετε, αποστέλλετε μέσω ηλεκτρονικού ταχυδρομείου ή με άλλο τρόπο,
        αποστέλλετε ή διαβιβάζετε οποιοδήποτε υλικό έχει σχεδιαστεί για να διακόπτει, να καταστρέφει
        ή να περιορίζει τη λειτουργικότητα οποιουδήποτε λογισμικού ή υλικού ή τηλεπικοινωνιακού
        εξοπλισμού που σχετίζεται με τον διαδικτυακό τόπο, καθώς, επίσης και τυχόν αρχεία ή
        προγράμματα. Μπορούμε να τερματίσουμε ή να περιορίσουμε τη χρήση της υπηρεσίας μας, αν
        πιστεύουμε εύλογα ότι έχετε παραβιάσει τους παρόντες όρους χρήσης ή συμμετέχετε στην
        παράνομη ή δόλια χρήση της υπηρεσίας.
      </p>
      <p>
        Διακήρυξη Αρχών:
        <br />
        Οι βασικές αξίες που διέπουν την παρούσα ιστοσελίδα είναι η εκτίμηση και ο σεβασμός.
        Επομένως, όταν χρησιμοποιείτε το VivianLab παρακαλείστε να τηρήσετε τους ακόλουθους κανόνες:
        <br />
        Απαγορεύεται η χρήση προσβλητικής, προκλητικής ή απειλητικής γλώσσας. Απαγορεύεται η χρήση
        γλώσσας που εισάγει διακρίσεις όσον αφορά το φύλο, την ταυτότητα ή την έκφραση του φύλου,
        την εθνικότητα, τη θρησκεία ή άλλη πεποίθηση, αναπηρία, σεξουαλικό προσανατολισμό ή ηλικία.
        <br />
        Δεν επιτρέπονται οι παρενοχλήσεις στο VivianLab. Ως παρενόχληση νοούνται εκφράσεις
        προφορικές ή γραπτές ή / και πράξεις που σκοπεύουν να προκαλέσουν οποιαδήποτε ψυχική ή
        σωματική ζημιά.
        <br />
        Σε κάθε περίπτωση που δεν τηρούνται αυτοί οι κανόνες, το VivianLab μπορεί να λάβει μέτρα
        όπως την απενεργοποίηση του λογαριασμού χρήστη ή / και της κλήσης της Δίωξης Ηλεκτρονικού
        Εγκλήματος.
        <br />
        ΟΙ ΠΑΡΟΝΤΕΣ ΟΡΟΙ ΧΡΗΣΗΣ ΤΡΟΠΟΠΟΙΗΘΗΚΑΝ ΤΕΛΕΥΤΑΙΑ ΦΟΡΑ ΤΗΝ 12/03/2025
      </p>
    </div>
  );
}

export default PatientTermsGreek;
