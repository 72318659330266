import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { isScrollingDisabled } from '../../ducks/ui.duck';
import { injectIntl, intlShape } from '../../util/reactIntl';
import { Page, LayoutSingleColumn, Footer } from '../../components';
import TopbarContainer from '../TopbarContainer/TopbarContainer';

import facebookImage from '../../assets/vivianlab-brandImage.png';
import twitterImage from '../../assets/vivianlab-brandImage.png';
import { withViewport } from '../../util/uiHelpers';
import { updateProfile } from '../ProfileSettingsPage/ProfileSettingsPage.duck';
import { useConfiguration } from '../../context/configurationContext';

import css from './TermsOfServicePage.module.css';
import { isLocaleGreek } from '../../util/genericHelpers';
import PatientTermsEnglish from './Patient/PatientTermsEnglish';
import PatientTermsGreek from './Patient/PatientTermsGreek';
import DoctorTosEnglish from './Doctor/DoctorTosEnglish';
import DoctorTosGreek from './Doctor/DoctorTosGreek';
import classNames from 'classnames';

export const TermsOfServicePageComponent = props => {
  const { intl, scrollingDisabled } = props;
  const config = useConfiguration();
  const isGreek = isLocaleGreek();

  const [scroll, setScroll] = useState(false);
  const siteTitle = config.marketplaceName;
  const schemaTitle = intl.formatMessage({ id: 'TermsOfServicePage.schemaTitle' }, { siteTitle });
  const schemaDescription = intl.formatMessage({
    id: 'TermsOfServicePage.schemaDescription',
  });

  const schemaImage = `${config.marketplaceRootURL}${facebookImage}`;
  useEffect(() => {
    let isMounted = true;
    if (typeof window !== 'undefined') {
      const handleScroll = () => {
        if (isMounted) {
          setScroll(window.scrollY > 0);
        }
      };
      window.addEventListener('scroll', handleScroll);
      return () => {
        isMounted = false; // This is the cleanup for event listener
        window.removeEventListener('scroll', handleScroll);
      };
    }
  }, []);
  const isHeaderSticky = scroll ? css.sticky : '';

  const [isDoctor, setDoctor] = useState(true);

  const renderContent = isDoctor ? (
    isGreek ? (
      <DoctorTosGreek />
    ) : (
      <DoctorTosEnglish />
    )
  ) : isGreek ? (
    <PatientTermsGreek />
  ) : (
    <PatientTermsEnglish />
  );

  return (
    <Page
      className={css.root}
      scrollingDisabled={scrollingDisabled}
      contentType="website"
      description={schemaDescription}
      title={schemaTitle}
      facebookImages={[{ url: facebookImage, width: 1200, height: 630 }]}
      twitterImages={[
        {
          url: `${config.marketplaceRootURL}${twitterImage}`,
          width: 600,
          height: 314,
        },
      ]}
      schema={{
        '@context': 'http://schema.org',
        '@type': 'WebPage',
        description: schemaDescription,
        name: schemaTitle,
        image: [schemaImage],
      }}
    >
      <LayoutSingleColumn
        className={css.pageRoot}
        topbar={<TopbarContainer isFullWidthHeader={true} isPostLandingPage />}
        footer={<Footer />}
      >
        <div className={css.pageContent}>
          <div className={css.tabs}>
            <span
              className={classNames({
                [css.active]: isDoctor,
              })}
              onClick={() => setDoctor(true)}
            >
              {intl.formatMessage({
                id: 'TermsOfServicePage.doctors',
              })}
            </span>
            <span
              className={classNames({
                [css.active]: !isDoctor,
              })}
              onClick={() => setDoctor(false)}
            >
              {intl.formatMessage({
                id: 'TermsOfServicePage.patients',
              })}
            </span>
          </div>
          {renderContent}
        </div>
      </LayoutSingleColumn>
    </Page>
  );
};

const { bool } = PropTypes;

TermsOfServicePageComponent.propTypes = {
  scrollingDisabled: bool.isRequired,
  // from injectIntl
  intl: intlShape.isRequired,
};

const mapStateToProps = state => {
  const { currentUser } = state.user;

  return {
    scrollingDisabled: isScrollingDisabled(state),
    currentUser,
  };
};

const mapDispatchToProps = dispatch => ({
  onUpdateProfile: data => dispatch(updateProfile(data)),
});

const TermsOfServicePage = compose(
  withRouter,
  connect(mapStateToProps, mapDispatchToProps),
  withViewport,
  injectIntl
)(TermsOfServicePageComponent);

export default TermsOfServicePage;
