import React from 'react';

function DoctorTosGreek() {
  return (
    <div>
      <h3>Όροι Χρήσης Ιστοσελίδας – Διαδικτυακού Τόπου</h3>
      <p>
        Με το παρόν καθορίζονται οι όροι χρήσης των υπηρεσιών μεσολάβησης που παρέχονται μέσω της
        ιστοσελίδας που βρίσκεται στη διαδικτυακή διεύθυνση www.vivianlab.gr & www.vivianlab.com &
        www.vivianlab.health ή στην σχετική εφαρμογή (εφ' εξής «Επιχείρηση» ή «Vivian Lab» ως
        διακριτικός τίτλος της Εταιρείας).
      </p>
      <p>
        O παραπάνω διαδικτυακός ιστότοπος & ιστοσελίδα (εφεξής ο «Ιστότοπος» ή «Ιστοσελίδα»)
        δημιουργήθηκε, ανήκει και λειτουργεί υπό τη διαχείριση της εταιρείας με την επωνυμία
        «ΒΙΒΙΑΝΛΑΜΠ ΜΟΝΟΠΡΟΣΩΠΗ Ι.Κ.Ε.» και το διακριτικό τίτλο «Vivian Lab», με έδρα στο Μαρούσι
        Αττικής, οδός Σάμου αρ. 47, με αρ. Γ.Ε.ΜΗ. 179795401000, Α.Φ.Μ. 802625789, Δ.Ο.Υ. Αμαρουσίου
        ηλεκτρονική διεύθυνση επικοινωνίας ως ανωτέρω ορίζεται info@vivianlab.com, η δε εταιρεία
        αποτελεί και την αποκλειστική νόμιμη δικαιούχο της παρούσας Ιστοσελίδας, του περιεχομένου
        και του ονόματος χώρου υπό τη διεθνή διαδικτυακή καταχώρηση ως εξής: www.vivianlab.gr &
        www.vivianlab.com & www.vivianlab.health, και κάθε άλλη παρεμφερή ονομασία με το οποίο αυτή
        συνδέεται ή επιλέξει να συνδεθεί στο μέλλον. Το γενικό περιεχόμενο προστατεύεται από την
        νομοθεσία περί πνευματικής ιδιοκτησίας, καθώς και ειδικότερα στοιχεία των ιστοσελίδων
        προστατεύονται από νόμους που σχετίζονται ενδεικτικά και όχι περιοριστικά με εμπορικό
        απόρρητο, εμπορικών & βιομηχανικών μυστικών και περιουσίας, αθέμιτου ανταγωνισμού,
        ηλεκτρονικού εμπορίου, ασφάλειας οικονομικών συναλλαγών και έτερες σχετικές νομοθεσίες. Δεν
        επιτρέπεται η τροποποίηση, αντιγραφή η μίμησή τους, και δεν πρέπει να αφαιρείται ή να
        τροποποιείται οποιαδήποτε ειδοποίηση περί πνευματικών δικαιωμάτων ή εμπορικών σημάτων, ούτε
        κάποια άλλη ειδοποίηση για την ιδιοκτησία.
      </p>
      <p>
        Αποδοχή των όρων χρήσης Η χρήση της ιστοσελίδας VivianLab γίνεται με αποκλειστική ευθύνη του
        επαγγελματία – Παρόχου – χρήστη και συνεπάγεται την πλήρη, ανεπιφύλακτη αποδοχή και συμφωνία
        εκ μέρους του, των παρακάτω όρων χρήσεως και των όρων της πολιτικής προστασίας προσωπικών
        δεδομένων. Οι παρακάτω όροι χρήσεως αφορούν τη χρήση της ιστοσελίδας VivianLab τόσο από
        επισκέπτη επαγγελματία – Πάροχο - χρήστη όσο και από εγγεγραμμένο στον ιστότοπο επαγγελματία
        – Πάροχο - χρήστη. Χρήση της ιστοσελίδας μας και εγγραφή σε αυτή, σημαίνει αποδοχή,
        συγκατάθεση, συναίνεση, έγκριση και συμφωνία εκ μέρους του επισκέπτη - χρήστη με τους όρους
        χρήσεως και την πολιτική προστασίας προσωπικών δεδομένων που ακολουθούμε. Διαδικτυακές
        υπηρεσίες
      </p>
      <p>
        Το VivianLab αποτελεί έναν καινοτόμο διαδικτυακό τόπο, ο οποίος εξειδικεύεται στο χώρο της
        συμβουλευτικής υγείας σε ένα γενικό και ολιστικό πλαίσιο υπό τη μορφή καταχώρησης και
        προβολής επαγγελματιών όλων των κλάδων της υγείας καθώς επίσης και επιχειρήσεων και
        επιστημών που δραστηριοποιούνται στο χώρο της υγείας σε μία ευρύτερη μορφή και ενασχόληση.
        Επομένως, το VivianLab είναι διαδικτυακός τόπος προβολής επαγγελματιών που μάλιστα
        συμπεριλαμβάνει πέραν των ιατρών και επιπλέον κατηγορίες επαγγελματιών και επιστημόνων που
        άπτονται του χώρου της υγείας, σε μια προσπάθεια να παρέχει στον τελικό χρήστη μια
        ολοκληρωμένη λύση στην προσπάθεια αναζήτησης συμβουλής και βοήθειας για κάθε ζήτημα υγείας,
        αυτοβελτίωσης, συμβουλευτικής και ενισχυτικής ψυχολογίας, φαρμακευτικής ή/και
        παραφαρμακευτικής κάλυψης και ούτω καθεξής.
      </p>
      <p>
        Επισημαίνεται ότι μέσω αυτού του διαδικτυακού τόπου, ο οποίος λειτουργεί αυστηρά ως
        διαμεσολαβητής, και όχι ως πάροχος, παρέχεται πλήθος υπηρεσιών με το χαρακτήρα της
        τήλε-συμβουλευτικής καθώς επίσης και η δυνατότητα καταχώρησης ραντεβού online, αποφεύγοντας
        άσκοπα τηλεφωνήματα και μετακινήσεις. Αναλυτικότερα, μέσω του VivianLab, οι επαγγελματίες –
        Πάροχοι - χρήστες μπορούν να προβάλλουν το επαγγελματικό τους προφίλ, το οποίο περιλαμβάνει
        ποικίλες πληροφορίες όπως στοιχεία επικοινωνίας, περιγραφή του προσώπου του επαγγελματία,
        τομείς στους οποίους εξειδικεύεται, παρεχόμενες υπηρεσίες και κόστος αυτών, ωράριο εργασίας
        και προαιρετικά παρέχεται μέσω του προφίλ στους ενδιαφερόμενους – χρήστες, είτε μέλη είτε
        επισκέπτες, να προγραμματίσουν διαδικτυακά το ραντεβού τους με τον επαγγελματία καθώς επίσης
        να πραγματοποιήσουν βιντεο-κλήση οnline συμβουλευτικής για λήψη πάσης φύσεως συμβουλών πάνω
        σε ζητήματα που αντιμετωπίζουν. Επίσης, αναφορικά στις επιχειρήσεις που δραστηριοποιούνται
        στο χώρο της συμβουλευτικής, μπορούν να προβάλλουν το προφίλ της επιχείρησής τους, το οποίο
        περιλαμβάνει πληροφορίες όπως στοιχεία επικοινωνίας, περιγραφή της επιχείρησης, τομείς στους
        οποίους εξειδικεύεται, παρεχόμενες υπηρεσίες και κόστος αυτών, προωθητικά πακέτα και
        προσφορές, ωράριο λειτουργίας και προαιρετικά παρέχεται μέσω του προφίλ στους
        ενδιαφερόμενους - χρήστες να προγραμματίσουν διαδικτυακά το ραντεβού τους, καθώς επίσης να
        πραγματοποιήσουν βίντεο-κλήση οnline συμβουλευτικής για λήψη συμβουλών πάνω σε ζητήματα που
        αντιμετωπίζουν. Ειδικότερα, σχετικά με τις υπηρεσίες online συμβουλευτικής, δημιουργήθηκαν
        και έχουν το χαρακτήρα της παροχής συμβουλευτικών, επικουρικών και επιβλεπουσών υπηρεσιών
        χρησιμοποιώντας τεχνολογίες πληροφοριών και επικοινωνιών για την ανταλλαγή έγκυρης
        πληροφορίας και αξιόπιστης επικοινωνίας μεταξύ των επαγγελματιών και των ενδιαφερομένων
        χρηστών του VivianLab για τη διάγνωση, αγωγή και πρόληψη ασθενειών, την έρευνα και εκτίμηση
        της κατάστασης ή του ανακύψαντος ζητήματος που καλείται ο επαγγελματίας – Πάροχος - χρήστης
        να επιμεληθεί και να παράσχει τις απαραίτητες υπηρεσίες. Περαιτέρω, οι συνεδρίες
        πραγματοποιούνται μέσω βιντεοκλήσης είτε στην πλατφόρμα Google Meetings/Calendly/Jitsi, είτε
        απευθείας μέσω της ιστοσελίδας του Vivian Lab, κατά την αποκλειστική κρίση της ιστοσελίδας.
      </p>
      <p>
        Σε καμία περίπτωση η υπηρεσία της online συμβουλευτικής δεν υποκαθιστά την κλινική ή/και
        απαραίτητη εξέταση και τη διενέργεια εργαστηριακών εξετάσεων που είναι απαραίτητα
        προκειμένου να τεθεί μια ασφαλής και τεκμηριωμένη επιστημονική διάγνωση. Απαραίτητη
        προϋπόθεση για τη συμμετοχή ενός επαγγελματία – παρόχου - χρήστη στην υπηρεσία οnline
        συμβουλευτικής είναι η γνωστοποίηση του αριθμού αδείας λειτουργίας ιατρείου, όπως παρέχεται
        από τον εκάστοτε ιατρικό σύλλογο ή εναλλακτικά του αριθμού άδειας άσκησης επαγγέλματος, αν
        πρόκειται για μη γιατρούς, με σκοπό να διασφαλιστεί ότι πρόκειται για νόμιμο επαγγελματία –
        Πάροχο - χρήστη αναγνωρισμένο από το επίσημο Ελληνικό κράτος. Ο Πάροχος είναι αποκλειστικά
        υπεύθυνος να υποβάλλει αληθή και ακριβή στοιχεία και εγγυάται τόσο για αυτά όσο και για τη
        νομιμότητα αυτών αλλά και τη νόμιμη κατοχή και χρήση τους. Ο Πάροχος ευθύνεται για κάθε
        θετική και αποθετική ζημία τυχόν υποστεί η εταιρεία για την παράβαση της παραπάνω
        υποχρέωσης. Σε καμία περίπτωση το VivianLab δεν φέρει ευθύνη για τις περιεχόμενο των
        συμβουλών του εκάστοτε επαγγελματία – χρήστη προς τον ενδιαφερόμενο, καθώς επίσης για τη
        διαχείριση των πληροφοριών αυτών από τη πλευρά του ενδιαφερόμενου – χρήστη. Σε κάθε
        περίπτωση τονίζεται ότι ο διαδικτυακός τόπος του VivianLab αποτελεί έναν διαμεσολαβητή στην
        παροχή των υπηρεσιών αυτών, παρέχοντας μόνο το μέσο για την παροχή των υπηρεσιών αυτών.
      </p>
      <p>
        Επομένως, το VivianLab είναι διαδικτυακός τόπος προβολής επαγγελματιών που μάλιστα
        συμπεριλαμβάνει πέραν των ιατρών και επιπλέον κατηγορίες επαγγελματιών και επιστημόνων που
        άπτονται του χώρου της υγείας, σε μια προσπάθεια να παρέχει στον τελικό χρήστη μια
        ολοκληρωμένη λύση στην προσπάθεια αναζήτησης συμβουλής και βοήθειας για κάθε ζήτημα υγείας,
        αυτοβελτίωσης, συμβουλευτικής και ενισχυτικής ψυχολογίας, φαρμακευτικής ή/και
        παραφαρμακευτικής κάλυψης και ούτω καθεξής. Επιπλέον Το Vivian Lab είναι μια διαδικτυακή
        ηλεκτρονική υπηρεσία μεσολάβησης για εγγεγραμμένους χρήστες, όπου μπορούν να παρακολουθήσουν
        οπτικοακουστικά μέσα (εκπαιδευτικά βίντεο), να αναγνώσουν αθρογραφία, να συμμετέχουν σε
        group webinars, να καταγράψουν τα συμπτώματά τους και να δουν πλάνα διατροφής και ασκήσεων,
        να λάβουν συμβουλές, καθώς και να συμμετέχουν σε hotlines με ειδικούς για εξειδικευμένες
        ερωτήσεις, να κλείσουμε ραντεβού / επικοινωνία μέσω συνομιλίας, ανταλλαγής μηνυμάτων,
        φωνητικής κλήσης, ή βιντεοκλήσης (Συνάντηση) με διπλωματούχο Επαγγελματία και να λάβουν
        πληροφορίες σχετικά με πάσης φύσεως ζητήματα που αφορούν την ευεξία . Περαιτέρω, οι
        συνεδρίες πραγματοποιούνται μέσω βιντεοκλήσης είτε στην πλατφόρμα Google Meetings ή Jitsi,
        είτε μέσω της ιστοσελίδας του Vivian Lab κατά την αποκλειστική κρίση της ιστοσελίδας. Εάν,
        ως πάροχος, επιλέξετε να πραγματοποιήσετε μια ομαδική συνεδρία ή γραμμή υποστήριξης,
        αναγνωρίζετε και συμφωνείτε ότι είστε αποκλειστικά υπεύθυνοι για το περιεχόμενο και την
        υλοποίηση της συνεδρίας. Επιπλέον, συμφωνείτε ότι δεν θα αναμένετε ούτε θα διεκδικήσετε
        οποιαδήποτε αποζημίωση, πληρωμή ή αμοιβή από το Vivian Lab υπό οποιεσδήποτε συνθήκες,
        συμπεριλαμβανομένων, ενδεικτικά, περιπτώσεων όπου κανένας χρήστης δεν συμμετάσχει στη
        συνεδρία.
      </p>
      <p>
        Ως εκ τούτου, αυτοί οι όροι χρήσης ρυθμίζουν τη σχέση μεταξύ της παρούσας ιστοσελίδας, ως
        διαμεσολαβητή στην παροχή οnline συμβουλευτικής και του τελικού χρήστη της ψηφιακής
        υπηρεσίας είτε ως ενδιαφερομένου, είτε ως επαγγελματία – πάροχου και ΟΧΙ την σχέση των δύο
        τελευταίων και αποκλειστικός υπεύθυνος για τις συμβουλές που δίνονται σε όσους κάνουν χρήση
        της υπηρεσίας είναι ο επαγγελματίας- χρήστης, για το περιεχόμενο την πρακτική και την
        ορθότητα των πληροφοριών που παρέχει, και ο ενδιαφερόμενος - χρήστης, για τη διαχείριση και
        αξιολόγηση των πληροφοριών αυτών.
      </p>
      <p>
        Όσοι Πάροχοι επαγγελματίες και επιχειρήσεις χρήστες που δραστηριοποιούνται στο χώρο δράσης
        και διαμεσολάβησης του VivialLab επιθυμούν να δημιουργήσουν διαδικτυακό προφίλ στην
        ιστοσελίδα του VivianLab σύμφωνα με τα παραπάνω, καλούνται να συμπληρώσουν τη σχετική οnine
        φόρμα εγγραφής με τα στοιχεία τους και να την υποβάλουν στο καθορισμένο πεδίο αποδεχόμενοι
        τους Όρους Χρήσης και την Πολιτική Προστασίας Απορρήτου της ιστοσελίδας. Η ορθότητα αυτών
        επιβεβαιώνεται από το VivianLab και εν συνεχεία ο Πάροχος επαγγελματίας/επιχείρηση/χρήστης
        εντάσσεται στο δίκτυο των προβαλλόμενων συνεργατών του VivianLab και διαθέτει το προσωπικό
        του προφίλ. Tο VivianLab δεν ορίζει συγκεκριμένο κόστος για την επίσκεψη, ούτε ελέγχει αν
        τηρεί ο επαγγελματίας χρήστης και υπό ποιο καθεστώς και συνθήκη τιμοκαταλόγου.
      </p>
      <p>
        Σημειώνεται ότι μέσω της ιστοσελίδας παρέχεται στους επισκέπτες/χρήστες η δυνατότητα να
        επικοινωνήσουν με το VivianLab για οποιοδήποτε ζήτημα, συμπληρώνοντας την αντίστοιχη φόρμα
        επικοινωνίας, αποδεχόμενοι τους Όρους Χρήσης και την Πολιτική Προστασίας Απορρήτου της
        Ιστοσελίδας.
      </p>
      <p>
        Ειδικότερα δε, σχετικά με τους επαγγελματίες – Παρόχους - χρήστες γίνεται ρητή μνεία ότι ο
        Πάροχος / παρέχων υπηρεσίες / επαγγελματίας χρήστης έλαβε γνώση και ρητά συναινεί με τους
        όρους και τις υποχρεώσεις που αναγράφονται στα κείμενα των : 1. Όροι Χρήσης, 2. Πολιτική
        Προστασίας Απορρήτου και 3. Χρήσης Cookies οι οποίοι ορίζουν το κάτωθι περιβάλλον
        συνεργασίας:
      </p>
      <p>Ορολογία:</p>
      <p>
        1. Χρήστης: Ο τελικός πελάτης/καταναλωτής της εταιρίας που αναζητά υπηρεσίες μέσω της
        ιστοσελίδας/πλατφόρμας.
        <br />
        2. Πλατφόρμα ή/και Marketplace: Online περιβάλλον/Ιστότοπος e-commerce που δίνει τη
        δυνατότητα στους χρήστες να αναζητήσουν υπηρεσίες από εξειδικευμένους επαγγελματίες της
        επιλογής τους, σύμφωνα με τη διαθεσιμότητά τους.
        <br />
        3. Provider ή/και Επιχείρηση: Η επιχείρηση/επαγγελματίας χρήστης που συνεργάζεται με την
        Εταιρεία, με σκοπό είτε την online παρουσία της στο Marketplace για την παροχή υπηρεσιών
        προς τον χρήστη της υπηρεσίας. Ο όρος "Provider" μπορεί να αναφέρεται είτε σε νομικό
        πρόσωπο, είτε σε φυσικό πρόσωπο που εκπροσωπεί μια επιχείρηση.
        <br />
        4. Εταιρική οδηγία: οποιαδήποτε οδηγία ή εντολή δίδεται μέσω του συνηθισμένου πλαισίου
        επικοινωνίας, π.χ. ηλεκτρονικού ταχυδρομείου ή/και της εφαρμογής, έγγραφο κ.α. από την
        Εταιρία ή την Ομάδα διοίκησης έργου στον Συνεργάτη σχετικά με την υλοποίηση του Έργου.
        <br />
        5. Έργο: Η εκτέλεση του έργου κατ' αποκοπή παροχής υπηρεσίας καθώς και πάσης φύσεως
        συνυφασμένη και σχετιζόμενη με αυτή υπηρεσία.
        <br />
        <br />
      </p>
      <p>
        Ένταξη στον Ιστότοπο: Οι Συνεργάτες, Επαγγελματίες – Πάροχοι - Χρήστες του Ιστοτόπου
        συμβάλλονται με την Εταιρεία με την πλήρη συμπλήρωση της σχετικής ηλεκτρονικής φόρμας που
        βρίσκεται στον Ιστότοπο και την αποδοχή των παρόντων όρων. Το κόστος συμμετοχής τους στον
        Ιστότοπο, είτε σε μορφή περιοδικής συνδρομής είτε/και μέσω άλλου τύπου κοστολόγησης ανάλογα
        με την τιμολογιακή πολιτική της Εταιρείας κατά περίπτωση έχει ήδη συμφωνηθεί.
      </p>
      Όροι προστασίας:
      <p>
        Οι Πάροχοι - Επαγγελματίες- Χρήστες του Ιστοτόπου οφείλουν να τηρούν απαρέγκλιτα τόσο το
        περιεχόμενο στο παρόν Γενικό Πλαίσιο Όρων Χρήσης, όσο και κάθε επιμέρους όρο που τίθεται με
        τις ad hoc κατά περίπτωση συμβάσεις που ενδέχεται να καταρτίσουν με την Εταιρεία, ενώ
        οφείλουν, κατά την συνεργασία τους με τον Ιστότοπο να τηρούν κάθε θεσπισμένη νομική διάταξη
        τόσο του Εθνικού, όσο και του Ευρωπαϊκού και Διεθνούς Δικαίου και τον Κώδικα Δεοντολογικής
        συμπεριφοράς στο Διαδίκτυο όπως ισχύει, ενώ απαγορεύεται να προβαίνουν σε πράξεις ή να
        διαμορφώνουν πρακτικές που παραβιάζουν τα δικαιώματα του Καταναλωτή, συνιστούν παραπλανητική
        διαφήμιση ή συντείνουν σε αθέμιτο ανταγωνισμό τόσο ως προς τους λοιπούς Πάροχος του
        Ιστοτόπου όσο και προς τον ίδιο τον Ιστότοπο.
      </p>
      <p>
        Οι Πάροχοι – Επαγγελματίες - Χρήστες του Ιστοτόπου δεσμεύονται και αναλαμβάνουν πλήρως την
        σχετική ευθύνη ότι θα παρέχουν προς τον Ιστότοπο τα πραγματικά τους προσωπικά και
        επαγγελματικά στοιχεία, όπως ενδεικτικά την επωνυμία, την διεύθυνση της επαγγελματικής τους
        έδρας, σε περίπτωση νομικού προσώπου τα πλήρη στοιχεία του νομίμου εκπροσώπου τους και του
        ορισμένου για την επικοινωνία με τον Ιστότοπο και τους επισκέπτες, τους χρήστες ή τα μέλη
        του προσώπου τα στοιχεία επικοινωνίας τους (ήτοι ενδεικτικά και όχι περιοριστικά αριθμοί
        σταθερών και κινητών τηλεφώνων, αριθμοί fax, διεύθυνση e-mail, domain names των
        επαγγελματικών τους ιστοσελίδων κ.λπ.), το ακριβές πεδίο δραστηριότητάς τους και τις
        κατηγορίες των παρεχόμενων από αυτούς υπηρεσιών. Ειδικότερα, τα στοιχεία αυτά, ως και κάθε
        άλλη πληροφορία ή ψηφιακό αρχείο που θα διαθέσει στον Ιστότοπο δεν θα πρέπει σε καμία
        περίπτωση να είναι ψευδή, παραπλανητικά ή ανακριβή, ούτε να οδηγούν, άμεσα ή έμμεσα, σε
        πρόκληση αναληθών παραστάσεων σε τρίτους ή να αποβλέπουν στην με οποιονδήποτε τρόπο διάθεση
        προϊόντων ή υπηρεσιών που αντίκειται στην κείμενη νομοθεσία. Περαιτέρω, τα ως άνω παρεχόμενα
        στοιχεία δεν θα πρέπει με οποιονδήποτε τρόπο να παραβιάζουν τις ισχύουσες διατάξεις του
        Συντάγματος και του Αστικού, Ποινικού και Εμπορικού Δικαίου, συμπεριλαμβανομένων των
        διατάξεων που αφορούν στο Δίκαιο Προστασίας Καταναλωτή και Διαφήμισης, στον Αθέμιτο
        Ανταγωνισμό, στην Πνευματική και Βιομηχανική Ιδιοκτησία και στην Προστασία της
        Προσωπικότητας, ούτε να περιέχουν ή να γεννούν κάθε είδους διακρίσεις που βαίνουν σε βάρος
        της αξίας του ανθρώπου ως και κάθε μορφής ζωής, ενώ θα πρέπει να είναι σύμφωνα με το σύνολο
        της ισχύουσας νομοθεσίας σε εθνικό, ευρωπαϊκό και διεθνές επίπεδο. Επαναλαμβάνεται ότι όλα
        τα απαραίτητα έγγραφα, άδειες ασκήσεως επαγγέλματος, ακαδημαϊκές ή/ και επαγγελματικές
        πιστοποιήσεις και λοιπά απαραίτητα νομιμοποιητικά έγγραφα που οφείλει να κατέχει ο Πάροχος
        τελούν υπό την αποκλειστική ευθύνη αυτού τόσο ως προς την νόμιμη κτήση και χρήση τους, όσο
        και ως προς την επικαιροποίηση και ισχύ τους. Οι Πάροχοι – Επαγγελματίες - Χρήστες συναινούν
        ρητά διά του παρόντος και με την παροχή των ως άνω στοιχείων και προσωπικών δεδομένων ότι
        παρέχουν προς την Εταιρεία την ρητή συναίνεσή τους αυτά να τηρηθούν σε βάση δεδομένων με
        κατηγοριοποίηση ανάλογα με την επαγγελματική τους δραστηριότητα και το είδος των παρεχόμενων
        από αυτούς Υπηρεσιών που η Εταιρεία τηρεί και να χρησιμοποιηθούν τόσο από την τελευταία και
        από κάθε πρόσωπο που έλκει δικαιώματα από αυτήν για κάθε σχετική με την λειτουργία του
        Ιστοτόπου επικοινωνία, ως και από κάθε τρίτο πρόσωπο, χρήστη του Ιστοτόπου ή Συνεργάτη της,
        κατόπιν αιτήματός του για την παροχή κάποιας Υπηρεσίας από αυτόν ή την παροχή οικονομικής
        προσφοράς. Η επικοινωνία θα μπορεί να πραγματοποιείται με κάθε πρόσφορο μέσο, όπως e-mail,
        sms, videocall/conference ή τηλεφωνική κλήση.
      </p>
      <p>
        Οι Πάροχοι – Επαγγελματίες - Χρήστες του Ιστοτόπου δεσμεύονται να προωθούν μέσω του
        Ιστοτόπου τις παρεχόμενες από αυτούς Υπηρεσίες κατά τρόπο σύμφωνο με την καλή πίστη και τα
        χρηστά ήθη, απέχοντας από κάθε πρακτική που συντείνει ή μπορεί να συμβάλλει με οποιονδήποτε
        τρόπο σε παραπλάνηση του καταναλωτή κατά τα οριζόμενα στον ν. 2251/1994 περί προστασίας
        Καταναλωτή όπως ισχύει, το ΠΔ 131.2003 και την κείμενη νομοθεσία στο ηλεκτρονικό εμπόριο και
        απέχοντας από οποιαδήποτε πρακτική ικανή να προκαλέσει βλάβη ή δυσλειτουργία στο δίκτυο,
        όπως μαζική αποστολή μηνυμάτων ("spaming") ή μαζική συγκέντρωση προσωπικών δεδομένων χρηστών
        με σκοπό την διαφήμιση εκτός του Ιστοτόπου.
      </p>
      <p>
        Οι Πάροχοι – Επαγγελματίες - Χρήστες του Ιστοτόπου οφείλουν να σέβονται και να συμβάλλουν
        στην διαφύλαξη της ορθής και ακώλυτης λειτουργίας του Ιστοτόπου, μεριμνώντας ώστε όλες οι
        πληροφορίες ή τα ψηφιακά αρχεία που θα αναρτά ή θα διοχετεύει στον Ιστότοπο θα είναι
        απολύτως ελεγμένα με δική του μέριμνα ώστε να μην περιέχουν οποιοδήποτε κακόβουλο ή εν γένει
        βλαβερό λογισμικό (malware) που μπορεί να προκαλέσει οποιαδήποτε ζημία ή καθυστέρηση στους
        servers ή τα συστήματα της Εταιρείας και παντός τρίτου με τον οποίο αυτή συνεργάζεται, και
        στους ηλεκτρονικούς υπολογιστές, τις ηλεκτρονικές συσκευές ή τους servers των λοιπών
        επισκεπτών, χρηστών ή μελών του Ιστοτόπου, όπως ενδεικτικά ιούς, trojans, worms κ.λπ., ούτε
        κάποιο άλλο αρχείο ή λογισμικό που θα παραβιάζει την ιδιωτικότητα ή τα προσωπικά δεδομένα
        της Εταιρείας, των εργαζομένων σε αυτήν, των λοιπών επαγγελματιών-συνεργατών της και κάθε
        επισκέπτη, χρήστη ή εγγεγραμμένου μέλους του Ιστοτόπου.
      </p>
      <p>
        Οι Πάροχοι – Επαγγελματίες - Χρήστες του Ιστοτόπου με την έναρξη της συνεργασίας τους με την
        Εταιρεία και τον Ιστότοπο, παρέχουν ρητά την συναίνεσή τους να δέχονται κάθε είδους
        επικοινωνίες, προσκλήσεις, αιτήματα των επισκεπτών, χρηστών ή μελών του Ιστοτόπου για
        οικονομικές προσφορές στο πλαίσιο της Λειτουργίας του Ιστοτόπου.
      </p>
      <p>
        Οι Πάροχοι – Επαγγελματίες - Χρήστες του Ιστοτόπου δεσμεύονται να ανταποκρίνονται άμεσα ή σε
        κάθε περίπτωση στον απολύτως αναγκαίο χρόνο σε κάθε επικοινωνία της Εταιρείας ή χρήστη των
        Υπηρεσιών του Ιστοτόπου της με αυτούς. Σε περίπτωση συνεργασίας του Παρόχου -
        Επαγγελματία-Χρήστη με χρήστη των Υπηρεσιών του Ιστοτόπου στα πλαίσια της λειτουργίας του, ο
        πρώτος οφείλει να επιδεικνύει τον δέοντα επαγγελματισμό και επιμέλεια κατά την παροχή των
        Υπηρεσιών του. Ο Πάροχος – Επαγγελματίας - Χρήστης του Ιστοτόπου έχει ενημερωθεί και
        αποδέχεται διά του παρόντος ότι στον Ιστότοπο δύναται να προβάλλονται θετικές ή και
        αρνητικές κριτικές της προσφερόμενης υπηρεσίας που παρείχε σε κάποιον χρήστη μέσω του
        Ιστοτόπου, η οποία δύναται ενδεικτικά να περιλαμβάνει βαθμολογίες ή και σχόλια απευθείας από
        τον χρήστη των υπηρεσιών του Ιστοτόπου.
      </p>
      <p>Οικονομικές Προσφορές – Αμοιβή</p>
      <p>
        Σε περίπτωση αιτήματος οικονομικής προσφοράς από επισκέπτη, χρήστη ή εγγεγραμμένο μέλος του
        Ιστοτόπου, οι Πάροχοι – Επαγγελματίες - Χρήστες του Ιστοτόπου να χορηγούν πλήρεις και
        αναλυτικές οικονομικές προσφορές, τις οποίες να κοινοποιούν είτε στον Ιστότοπο είτε στον
        αιτούντα χρήστη των Υπηρεσιών του Ιστοτόπου, ανάλογα με τον κατά περίπτωση οριζόμενο από τον
        Ιστότοπο τρόπο. Η προσφορά αυτή θα πρέπει να περιλαμβάνει και τις κάθε είδους δαπάνες και
        φορολογικές ή άλλες επιβαρύνσεις του Παρόχου - Επαγγελματία και τον δεσμεύει απολύτως
        απέναντι στον Ιστότοπο και τον χρήστη των Υπηρεσιών του Ιστοτόπου, ώστε δεν δύναται να την
        αυξήσει, άμεσα ή έμμεσα, καθ' οιονδήποτε τρόπο, στο πλαίσιο της παροχής των προσφερόμενων
        κατόπιν σχετικής αίτησης Υπηρεσιών.
      </p>
      <p>
        Τέλος, ο Πάροχος αξιολογείται και αμείβεται άμα την περαίωση του εκάστοτε σταδίου εκτέλεσης
        έργου προσφέρων ανεξάρτητες και αυτόβουλες πράξεις και ενέργειες διεκπεραίωσης αυτού.
        Επομένως, λαμβάνει αμοιβή με την ολοκλήρωση εκτέλεσης εκάστου σταδίου του έργου ή/και της
        υπηρεσίας που αναλαμβάνει.
      </p>
      <p>
        Η Εταιρεία έχει δικαίωμα, στα πλαίσια της εμπορικής της πολιτικής να προχωρά σε προωθητικές
        ενέργειες, συμπεριλαμβανομένης μεταξύ άλλων και της χορήγησης διατακτικών. Σε περίπτωση όπου
        χρήστης, χρησιμοποιήσει διατακτική για τη χρήση υπηρεσιών της ιστοσελίδας, ο ειδικός υγείας
        (πάροχος) θα αμείβεται από την εταιρεία και όχι από τον χρήστη.
      </p>
      <p>
        Στην περίπτωση αυτή, ο πάροχος οφείλει να εκδώσει τιμολόγιο ή απόδειξη παροχής υπηρεσιών
        (και όχι απόδειξη είσπραξης) εντός επτά (7) ημέρων από την ημέρα της συνεδρίας, στα στοιχεία
        της εταιρείας. Εάν, εντός του ανωτέρω χρονικού διαστήματος, δεν εκδοθεί το αντίστοιχο
        παραστατικό η εταιρεία έχει το δικαίωμα να αρνηθεί την πληρωμή.
      </p>
      <p>
        Ενδεικτικά, οι τρόποι πληρωμής που συνήθως επιλέγονται είναι οι εξής:
        <br />
        ~ Οι πληρωμές πραγματοποιούνται μέσω της εφαρμογής ¨stripe¨, όπου βεβαίως ο χρήστης της
        εφαρμογής οργανώνει τον λογαριασμό του για τη λειτουργία της, αποδέχεται αυτοδικαίως και
        αυτομάτως τους Όρους και Προϋποθέσεις του και κατόπιν ακολουθεί τα βήματα που απαιτεί η
        εφαρμογή ώστε να ολοκληρωθεί η διαδικασία και να πραγματοποιούνται οι πάσης φύσεως
        οικονομικές συναλλαγές/πληρωμές. Το Vivian Lab δε συμμετέχει κατ' οιοδήποτε τρόπο σε αυτήν
        τη διαδικασία κι επομένως δε φέρει καμία ευθύνη σχετικά με πάσης φύσεως οικονομική
        συναλλαγή/πληρωμή πραγματοποιηθεί ή όχι. Αναλυτικά, μπορείτε να διαβάσετε
        http://vivianlab.com/faq :
        <br />
        ~ Ο χρήστης - επισκέπτης ή μέλος - πρέπει να προσθέσει την πιστωτική του κάρτα για να
        κλείσει ραντεβού. Η πιστωτική κάρτα δεν χρεώνεται, μόνο προ εγκρίνεται και το ποσό είναι
        δεσμευμένο για την διενέργεια της επικοινωνίας.
        <br />
        ~ Εάν η επικοινωνία πραγματοποιηθεί με οποιαδήποτε τρόπο όπως ορίστηκε, ο Επαγγελματίας -
        Πάροχος πρέπει να κάνει κλικ σε μία επιλογή που θα παραλάβει μέσω email ή στην ηλεκτρονική
        πλατφόρμα πληρωμών και να επιβεβαιώσει τη συναλλαγή εντός 7 ημερών, προκειμένου να
        πραγματοποιηθεί η πληρωμή.
        <br />
        ~ Μόλις επιλέξει την επιλογή χρέωσης / αποδοχής στο σχετικό πεδίο, η πιστωτική κάρτα του
        χρήστη χρεώνεται εντός 24 ωρών και ο Επαγγελματίας – Πάροχος - Χρήστης λαμβάνει τα χρήματά
        του στον τραπεζικό λογαριασμό που έδωσε μέσω της εφαρμογής 'stripe' εντός 5 ημερών.
        <br />
        ~ Ο επαγγελματίας – Πάροχος – χρήστης είναι υπεύθυνος να στείλει στον χρήστη τη σχετική
        απόδειξη / παραστατικό για τη συνεδρία / υπηρεσία που πραγματοποιήθηκε με e-mail απευθείας
        στον χρήστη επισκέπτη - μέλος στον οποίο προσέφερε τις υπηρεσίες του σύμφωνα με το προσωπικό
        φορολογικό και λογιστικό καθεστώς στο οποίο υπάγεται.
        <br />
        ~ Εάν ο επαγγελματίας – Πάροχος – Χρήστης δεν επιβεβαιώσει την πληρωμή εντός 7 ημερών, δεν
        θα λάβει ποτέ την πληρωμή παρόλο που πραγματοποιήθηκε η συνεδρία και τα χρήματα θα
        αποδεσμευτούν από τηνπιστωτική κάρτα του χρήστη εντός 7 ημερών.
        <br />
        ~ Επιπροσθέτως των ανωτέρω, προς επιβεβαίωση της υπηρεσίας που προσφέρθηκε μέσω του Vivian
        Lab το αυτοματοποιημένο σύστημα του Vivian Lab εκδίδει απόδειξη παροχής της υπηρεσίας για
        χρήση από την εφαρμογή / πλατφόρμα. Σε καμία περίπτωση η απόδειξη του αυτοματοποιημένου
        συστήματος δεν αντικαθιστά ή υποκαθιστά το φορολογικό παραστατικό που επιλέγει ή που
        υποχρεούται να εκδώσει ο επαγγελματίας – Πάροχος – χρήστης για δικούς του φορολογικούς και
        λογιστικούς λόγους και προωθεί στον χρήστη που έλαβε την υπηρεσία ως ανωτέρω.
        <br />
        ~ Το Vivian Lab έχει το δικαίωμα να επιστρέψει στον χρήστη τα χρήματα εάν η εταιρεία
        πιστεύει ότι η διαδικασία ή η υπηρεσία δεν εκπληρώθηκε ή ολοκληρώθηκε όπως προβλέπεται. Σε
        αυτήν την περίπτωση, ακόμα κι αν ο Επαγγελματίας έχει ήδη λάβει τα χρήματά του στον
        τραπεζικό λογαριασμό μέσω της ηλεκτρονικής εφαρμογής, το Vivian Lab μπορεί να τα αποσύρει
        από τον λογαριασμό του Επαγγελματία και να τα επιστρέψει στον χρήστη.
        <br />
        ~ Σημειώνεται ότι το Vivian Lab δεν χρεώνει κανένα ποσό για τις υπηρεσίες διαμεσολάβησης που
        προσφέρει και δεν επιζητά κατά αυτόν τον τρόπο έσοδα.
        <br />
        ~ Όταν ο επαγγελματίας – Πάροχος – χρήστης προσθέτει τα διαπιστευτήριά και τα στοιχεία του
        για τη διαδικασία, αποδέχεται απευθείας τους Όρους και Προϋποθέσεις αυτής και επομένως το
        Vivian Lab δεν είναι υπεύθυνη για τις πληρωμές και τη συνολική διαδικασία.
        <br />
        ~ Εάν υπήρχε κάποιο τεχνικό πρόβλημα και ο Επαγγελματίας – Πάροχος – Χρήστης ή ο χρήστης
        επισκέπτης - μέλος δεν μπορούσαν να συμμετάσχουν στη συνάντηση επειδή δεν ήταν εφικτή η
        διαδικτυακή σύνδεση ή επειδή η πλατφόρμα δεν λειτουργούσε, τότε το Vivian Lab μπορεί να
        ακυρώσει τη συνεδρία δεν θα υπάρξει χρέωση και επομένως ο επαγγελματίας – Πάροχος - χρήστης
        δεν δικαιούται να λάβει πληρωμή, Αυτό παραμένει στη διακριτική ευχέρεια του Vivian Lab.
        <br />
        ~ Το Vivian Lab έχει πλήρη διακριτική ευχέρεια και εξουσία να ακυρώνει πληρωμές, να
        επιστρέφει χρήματα στους χρήστες, να μην πληρώνει τον επαγγελματία – Πάροχο – χρήστη εάν
        μέρος ή το σύνολο της διαδικασίας δεν ακολουθήθηκε. Το Vivian lab δεν χρειάζεται να δώσει
        εξηγήσεις σε οιαδήποτε σχετιζόμενο μέρος σχετικά με αυτή την απόφαση αυτή. Ειδικότερα δε, σε
        περίπτωση που ένας επαγγελματίας – Πάροχος – χρήστης ακυρώσει τέσσερα (4) προγραμματισμένα
        μέσω του Vivian Lab ραντεβού προς παροχή υπηρεσιών παρέχεται αυτοδικαίως το δικαίωμα στο
        Vivian Lab να διακόψει την πάσης φύσεως συνεργασία με τον επαγγελματία – Πάροχο – χρήστη και
        να αφαιρέσει την καταχώρησή του από τις επιλογές της πλατφόρμας. Όλες οι πληρωμές, που
        πραγματοποιούνται με χρήση πιστωτικής, χρεωστικής και προπληρωμένης κάρτας, διεκπεραιώνονται
        μέσω της ψηφιακής πλατφόρμας ηλεκτρονικών πληρωμών 'stripe' η οποία πληροί τις προδιαγραφές
        που επιβάλλει τα πρότυπα για την ασφάλεια δεδομένων καρτών πληρωμών. Παράλληλα, κατά τις
        ηλεκτρονικές πληρωμές με κάρτα, εφαρμόζεται ισχυρή ταυτοποίηση πελάτη κατά το πρωτόκολλο 3-D
        secure, σε συμμόρφωση με την Ευρωπαϊκή Οδηγία 2015/2366 (PSD2) και τις απαιτήσεις για ισχυρή
        ταυτοποίηση πελάτη.
        <br />
        (Strong Customer Authentication).
        <br />
        <br />
        Παροχής υπηρεσίας - συμβουλευτική:
      </p>
      <p>
        Κατά τη διάρκεια ισχύος της συνεργασίας και επομένως της χρήσης της ιστοσελίδας από τον
        επαγγελματία συνεργάτη για την παροχή υπηρεσιών προς τον τελικό χρήστη, ο Πάροχος θα εκτελεί
        δέσμη κατ' αποκοπή υπηρεσιών για την Εταιρεία, όπως καθορίζεται στη παρούσα.[GT3] [GS4]
      </p>
      <p>
        Ως ανωτέρω σημειώνεται ο Πάροχος είναι νομικό ή φυσικό πρόσωπο το οποίο έχει κατανοήσει τον
        σκοπό της εταιρείας και τον τρόπο λειτουργίας της και αφού έχει διαβάσει τους γενικότερους
        και ειδικότερους όρους της ιστοσελίδας, συμφωνεί και αναλαμβάνει να επιτελεί κατ' αποκοπή το
        έργο του Συνεργάτη σε συνεργασία με την εταιρεία.
      </p>
      <p>
        Το περιβάλλον και η συνθήκη συνεργασίας όπως και αν οριστεί, καθοριστεί ή προχωρήσει στην
        υλοποίησή της σε καμία περίπτωση δεν αποτελεί σύμβαση αποκλειστικής συνεργασίας και δεν
        περιορίζει το δικαίωμα του Συνεργάτη να παρέχει υπηρεσίες ή πάσης φύσεως συνεργασίες σε
        τρίτους ή να ασκεί έτερη επιχειρηματική δραστηριότητα και να παρέχει περαιτέρω ανεξάρτητες
        με το VivianLab υπηρεσίες εφόσον το επιθυμεί. Επομένως, με τη συνεργασία μεταξύ του
        επαγγελματία συνεργάτη και του VivianLab δεν δημιουργείται σε καμία περίπτωση σχέση
        εξαρτημένης ή ανεξάρτητης εργασίας μεταξύ της εταιρείας και του Συνεργάτη και παραμένουν
        ανεξάρτητοι επαγγελματίες για τους σκοπούς της παρούσας Σύμβασης. Ο Πάροχος αναγνωρίζει και
        δηλώνει ρητά ότι είναι ανεξάρτητος Πάροχος της εταιρείας και ότι τίποτα στην συνεργασία ή
        στη φύση εκτέλεσης των υπηρεσιών που προσφέρει υπό τους όρους και τις συμφωνίες που ορίζουν
        το περιβάλλον συνεργασίας δεν τον καθιστά υπάλληλο ή εργαζόμενο της εταιρείας και δεν πρέπει
        να θεωρεί τον εαυτό του ως τέτοιο. Συνεπώς, ο Πάροχος δεν έχει και δεν θα ασκήσει ούτε θα
        θεωρήσει ότι έχει οποιαδήποτε εξουσία να συμμετέχει ή να συνάπτει οποιαδήποτε σύμβαση ή να
        αναλαμβάνει οποιαδήποτε δέσμευση ή υποχρέωση για, εξ ονόματος ή για λογαριασμό του
        VivianLab.
      </p>
      <p>
        Επειδή η αξιοπιστία και η ακρίβεια αποτελούν εργαλεία για την εύρυθμη λειτουργία της
        συνεργασίας ο Πάροχος είναι υποχρεωμένος να τηρεί τους χρόνους, όπως αυτοί ορίζονται από την
        πλατφόρμα, ιδιαιτέρως δε να παρέχει την υπηρεσία που συμφωνήθηκε εντός του χρόνου που
        αναγράφεται στην πλατφόρμα ανά περίπτωση. Σε περίπτωση μη ικανότητας/δυνατότητας ολοκλήρωσης
        ή τήρησης των χρόνων, οφείλει να ενημερώσει έγκαιρα την εταιρεία εγγράφως. Ομοίως, σε
        περίπτωση μη δυνατότητας ή ικανότητας να εκτελέσει το έργο ο Συνεργάτης, οφείλει να
        ενημερώσει την εταιρία.
      </p>
      <p>
        Ομοίως, απαραίτητο είναι να διαθέτουμε τις απαραίτητες πιστοποιήσεις, άδειες και
        νομιμοποιητικά έγγραφα και επομένως ο Πάροχος είναι υπεύθυνος για την απόκτηση τυχόν
        απαραίτητων αδειών ή εγγραφών που απαιτούνται για την εκτέλεση της υπηρεσίας που προσφέρει
        επιπροσθέτως, ενδεχομένως, όλων των αδειών ασκήσεως επαγγέλματος. Επιπλέον, οφείλει να
        εγχειρίσει στην εταιρεία αντίγραφο των παραπάνω και βεβαίως υποχρεούται να ενημερώσει την
        εταιρεία σε περίπτωση λήξης ή απώλειας ή παύσης ισχύος των ανωτέρω αδειών.
      </p>
      <p>
        Καθώς οι δυσκολίες και οι αστοχίες είναι μέσα στην επαγγελματική δραστηριότητα ο Πάροχος
        είναι αποκλειστικά υπεύθυνος να διασφαλίζει ότι είναι ασφαλισμένος με πλήρη κάλυψη που
        απαιτείται για την εκτέλεση της υπηρεσίας που προσφέρει. Η εχεμύθεια και η εμπιστοσύνη είναι
        σημαντικοί όροι συνεργασίας και για αυτόν τον λόγο αμφότεροι VivianLab και ο Πάροχος
        δεσμεύονται σε καθεστώς πλήρους εχεμυθείας και προστασίας του απορρήτου της συνεργασίας
        μεταξύ τους, ο δε Πάροχος δηλώνει ρητά ότι θα απέχει από κάθε ενέργεια να χρησιμοποιήσει ή
        να επωφεληθεί από τις πληροφορίες που λαμβάνει από τους χρήστες της Πλατφόρμας για
        οποιονδήποτε άλλο σκοπό, εκτός από την εκτέλεση του εκάστοτε σταδίου της υπηρεσίας που έχει
        αναλάβει αποκλειστικά. Σε κάθε περίπτωση όλοι οι συνεργαζόμενοι οφείλουν να ενεργούν με καλή
        πίστη και συναλλακτικά ήθη και με σκοπό τη σωστή και εύρυθμη συνεργασία. Προς τούτο ορίζεται
        καθήκον ενημερώσεως σχετικά με πάσης φύσεως επιβλαβές ή δυσχερές περιστατικό ήθελε υποπέσει
        στην αντίληψη του ενός ή του άλλου συμβαλλόμενου μέρους με υποχρέωση όπως άμεσα και αμελλητί
        διενεργηθεί ενημέρωση και προταθεί αρωγή προς αντιμετώπιση και επίλυσή του.
      </p>
      <p>
        Επειδή ο κάθε επαγγελματίας / Πάροχος γνωρίζει καλύτερα το επίπεδο της εργασίας του διατηρεί
        το αποκλειστικό δικαίωμα και την ευθύνη να καθορίσει τον τρόπο, τη μέθοδο και τα μέσα με τα
        οποία θα εκτελεσθούν οι απαραίτητες ενέργειες για την εκτέλεση μέρους ή συνόλου του έργου
        ή/και υπηρεσίας που προσφέρει, περαιτέρω δε, ενεργεί άνευ ενδελεχούς ελέγχου και ρητής
        εντολής της Εταιρείας σε δικό του πρόγραμμα και χρόνο υπό το χρονοδιάγραμμα που η πλατφόρμα
        θέτει προς τον επισκέπτη – χρήστη, σε συνδυασμό με την αποτελεσματικότητα και την αρτιότητα
        των ενεργειών του στα πλαίσια της επαγγελματικής και επιστημονικής του κατάρτισης με την
        μορφή του αναδόχου. Η εταιρεία δεν θα έχει υποχρέωση να παρέχει εκπαίδευση στον Πάροχο για
        την εκτέλεση των συμφωνηθέντων στο πλαίσιο εκτέλεσης της συνεργασίας.
      </p>
      <p>
        Επιμέρους: Ρητά απαγορεύεται σε όλους τους χρήστες, ήτοι επισκέπτη – χρήστη ή επαγγελματία -
        Πάροχο – χρήστη να δημιουργεί ψεύτικους λογαριασμούς με δόλιες προθέσεις, να συλλέγει ή να
        αποθηκεύει προσωπικά δεδομένα άλλων χρηστών με οποιοδήποτε τρόπο. Το VivianLab διατηρεί το
        δικαίωμα να επιδιώξει την ικανοποίηση του για κάθε ζημία του από την παραβίαση των άνω
        απαγορεύσεων από τον οιοδήποτε χρήστη και να προβεί σε οποιαδήποτε σχετική νομική ενέργεια,
        διαδικασία και λήψη νόμιμων διορθωτικών εξώδικων ή δικαστικών μέτρων με στόχο την αποτροπή
        παραβίασης των παρόντων όρων χρήσης.
      </p>
      <p>
        Κάθε επισκέπτης ή επαγγελματίας - Πάροχος - χρήστης είναι υπεύθυνος για τη χρήση του
        VivianLab και μπορεί να χρησιμοποιεί τις υπηρεσίες του μόνο για νόμιμους και θεμιτούς
        σκοπούς. Δεν επιτρέπεται σε κανέναν να χρησιμοποιεί το VivianLab με τρόπο που θα μπορούσε να
        καταστρέψει, να απενεργοποιήσει ή να βλάψει τους διακομιστές ή τα δίκτυά μας, ή με τρόπους,
        οι οποίοι παρεμβαίνουν στη χρήση και στην εκμετάλλευση των υπηρεσιών του VivianLab από
        οποιοδήποτε άλλο μέλος-επισκέπτη- συνεργάτη χρήστη. Απαγορεύεται στον επισκέπτη – χρήστη να
        κάνει παράνομη κατάχρηση των υπηρεσιών μας προγραμματίζοντας ραντεβού με Πάροχο -
        επαγγελματία - χρήστη το οποίο δεν προτίθεται να τηρήσει.
      </p>
      <p>
        Οι πληροφορίες και παρουσιάσεις που παρέχονται στο site - portal εκφράζουν τις προσωπικές
        απόψεις των συγγραφέων – συνεργατών, έχουν αποκλειστικά ενημερωτικό και συμβουλευτικό
        χαρακτήρα και δεν είναι δυνατόν να υποκαταστήσουν τη συμβουλή και τοποθέτηση των
        εξειδικευμένων επαγγελματιών συνεργατών – Παρόχων. Ο χρήστης – επισκέπτης δεν πρέπει να
        βασίζεται στις πληροφορίες που παρέχονται στο VivianLab ως υποκατάστατο επιστημονικής
        συμβουλής και τοποθέτησης και ούτε αυτές αντικαθιστούν με οιονδήποτε τρόπο την επαγγελματική
        συμβουλή, εξέταση, διάγνωση και θεραπεία που προτείνεται ατομικά στον χρήστη από τον
        επαγγελματία χρήστη. Επομένως, το περιεχόμενο του Ιστότοπου όπως ενδεικτικά και όχι
        περιοριστικά, τα κείμενα, οι εικόνες και οι πληροφορίες που παρουσιάζονται από τους
        συντάκτες του, αλλά και οποιοδήποτε άλλο υλικό συμπεριλαμβάνεται στην ιστοσελίδα
        χρησιμοποιείται με μοναδικό σκοπό την ενημέρωση και πληροφόρηση των επισκεπτών – χρηστών του
        Γίνεται ιδιαίτερη μνεία ότι καμία δήλωση ή ανάρτηση στο VivianLab δεν μπορεί να
        εκλαμβάνεται, ότι είναι άσκηση επαγγελματικής και εξειδικευμένης συμβουλευτικής υπηρεσίας
        και φροντίδας. Ο χρήστης – επισκέπτης κάνει χρήση του VivianLab, των προτεινόμενων
        επαγγελματιών – συνεργατών και των προβλεπόμενων υπηρεσιών αποκλειστικά και μόνο με δική του
        ευθύνη. Το VivianLab δεν φέρει κανενός είδους ευθύνη για ακυρώσεις ή ανεκπλήρωτες
        συναντήσεις (ραντεβού) με τον επισκέπτη - χρήστη ή με τον επαγγελματία χρήστη ή / και
        οποιουδήποτε είδους ζημία προκύψει στους χρήστες από αυτές.
      </p>
      <p>
        Σημειώνουμε προς τους επαγγελματίες - Πάροχους – χρήστες ότι το VivianLab μπορεί να παρέχει
        συνδέσμους για ιστοσελίδες τρίτων. Μπορεί επίσης να επιλέγει και να δίνει προτεραιότητα σε
        συγκεκριμένες ιστοσελίδες, σύμφωνα με τους όρους αναζήτησης που εισάγει ο επισκέπτης –
        χρήστης, να επιτρέπει στους διαφημιζόμενούς του να απαντήσουν στην αναζήτηση ορισμένων όρων
        με διαφημίσεις ή με το περιεχόμενο των χορηγών. Σε καμία περίπτωση το VivianLab δεν συνιστά
        και δεν συνυπογράφει το περιεχόμενο από ιστοσελίδες τρίτων, δεν ελέγχει τη διαθεσιμότητα, το
        περιεχόμενο, την πολιτική προστασίας των προσωπικών δεδομένων, την ποιότητα και την
        πληρότητα των υπηρεσιών άλλων web sites και ιστοσελίδων στα οποία παραπέμπει μέσω "δεσμών",
        hyperlinks ή διαφημιστικών banners. Έτσι, για οποιοδήποτε πρόβλημα παρουσιασθεί κατά την
        επίσκεψη-χρήση τους, ο εκάστοτε χρήστης, ήτοι επισκέπτης, είτε μέλος, είτε επαγγελματίας,
        οφείλει να απευθυνθεί απευθείας στα αντίστοιχα web sites και σελίδες, τα οποία και φέρουν
        ακέραια τη σχετική ευθύνη για την παροχή των υπηρεσιών τους. Το VivianLab σε καμία περίπτωση
        δεν πρέπει να θεωρηθεί, ότι υιοθετεί ή αποδέχεται το περιεχόμενο ή τις υπηρεσίες των web
        sites και των σελίδων στα οποία παραπέμπει, ότι συνδέεται με αυτά κατά οποιονδήποτε άλλο
        τρόπο ή ότι εγγυάται για αυτά. Το VivianLab δεν έχει καμία ευθύνη για οιοδήποτε είδους
        επικοινωνία του χρήστη-επισκέπτη με τους τρίτους – παρόχους υπηρεσιών που διαφημίζονται στο
        VivianLab και για την οποιαδήποτε τυχόν συναλλαγή που μπορεί να προκύψει από τη μεταξύ τους
        σχέση. Ωστόσο, το VivianLab αν επισημανθούν σφάλματα από τον επισκέπτη – χρήστη ή/και τον
        επαγγελματία – Πάροχο - χρήστη θα επιδιώξει να καταβληθεί άμεσα κάθε προσπάθεια για την
        διόρθωσή τους. Παρόλα αυτά ο κάθε χρήστης χρησιμοποιεί με αποκλειστικά δική του ευθύνη
        οποιαδήποτε στοιχεία και υλικό που είναι διαθέσιμα και εμπεριέχονται σ' αυτόν τον δικτυακό
        τόπο και παρέχονται χωρίς καμία απολύτως εγγύηση για την ορθότητα και την ακρίβειά τους και
        το VivianLab δε φέρνει ουδεμία ευθύνη, άμεση ή έμμεση για τυχόν ζημία (θετική ή αποθετική)
        που μπορεί να επέλθει σε οποιονδήποτε από τη χρήση του δικτυακού του τόπου ή και στοιχείων
        που περιέχονται σ' αυτόν. Το VivinLab διατηρεί το αποκλειστικό δικαίωμα ανά πάσα στιγμή,
        χωρίς προειδοποίηση των χρηστών, δύναται να πραγματοποιήσει αλλαγές και βελτιώσεις που κατά
        την κρίση του θα καταστήσουν ευχερέστερη τη λειτουργία της ιστοσελίδας.
      </p>
      <p>
        Η χρήση της ιστοσελίδας VivianLab και όλων των υπηρεσιών και του περιεχόμενου της υπόκεινται
        στους ανωτέρω όρους χρήσεως. Αν ο Πάροχος -επαγγελματίας - χρήστης θεωρεί τους όρους αυτούς
        μη αποδεκτούς για τον ίδιο, για οποιονδήποτε λόγο, παρακαλείται να μην κάνει χρήση της
        ιστοσελίδας. Η χρήση της ιστοσελίδας VivianLab από τον επαγγελματία – συνεργάτη συνιστά
        πλήρη αποδοχή, συναίνεση, συγκατάθεση έγκριση και συμφωνία εκ μέρους του όλων των παρόντων
        όρων χρήσεως και των όρων της πολιτική προστασίας προσωπικών δεδομένων.
      </p>
      <p>
        Περιορισμός Ευθύνης Σε καμία περίπτωση το VivianLab δεν ευθύνεται έναντι του παρόχου της
        υπηρεσίας για οποιεσδήποτε απώλειες ή έξοδα του ή τρίτων ή για οποιαδήποτε θέματα που
        σχετίζονται με τις προσφερθείσες υπηρεσίες, συμβουλές ή οποιεσδήποτε άλλες πτυχές της
        συνολικής συνεργασίας ή των υποχρεώσεών του, συμπεριλαμβανομένης οποιασδήποτε απώλειας
        αποζημίωσης ή εσόδων ή απώλειας κερδών ή ειδικών, παρεπόμενων, τιμωρητικών ή επακόλουθων
        ζημιών που συμβουλεύτηκαν για τη δυνατότητα τέτοιων ζημιών, αλλά και έναντι του επισκέπτη -
        χρήστη.
      </p>
      <p>
        Ο επαγγελματίας Πάροχος – χρήστης υποχρεούται να αποζημιώσει, να υπερασπιστεί και να
        διατηρήσει το VivianLab αβλαβές από και εναντίον οποιωνδήποτε και όλων των αξιώσεων,
        ευθύνης, αγωγών, ζημιών, ζημιών και κρίσεων τρίτων, και να καταβάλλει όλα τα έξοδα
        (συμπεριλαμβανομένων των αμοιβών και εξόδων δικηγόρου) σε σχέση με την προετοιμασία ή
        υπεράσπιση οποιασδήποτε εκκρεμούσας ή επαπειλούμενης αξίωσης ή οποιασδήποτε ενέργειας ή
        διαδικασίας που προκύπτει από την παροχή υπηρεσιών βάσει της συνεργασίας.
      </p>
      <p>
        Δεδομένης της φύσης και του όγκου του διαδικτύου, υπό οποιεσδήποτε συνθήκες,
        συμπεριλαμβανομένης και της περίπτωσης αμέλειας, το VivianLab δεν ευθύνεται για οποιασδήποτε
        μορφής ζημία υποστεί ο επισκέπτης/χρήστης των σελίδων, υπηρεσιών, επιλογών και περιεχομένων
        του, στις οποίες προβαίνει με δική του πρωτοβουλία.
      </p>
      <p>
        Τα περιεχόμενά του παρέχονται «όπως ακριβώς είναι» χωρίς καμία εγγύηση εκπεφρασμένη ή και
        συνεπαγόμενη με οποιοδήποτε τρόπο. Στο μέγιστο βαθμό και σύμφωνα με το νόμο, το VivianLab
        αρνείται όλες τις εγγυήσεις εκπεφρασμένες ή και συνεπαγόμενες, συμπεριλαμβανομένων, μεταξύ
        άλλων και αυτών, οι οποίες συνεπάγονται την εμπορευσιμότητα και την καταλληλόλητα για ένα
        συγκεκριμένο σκοπό.
      </p>
      <p>
        Το VivianLab δεν εγγυάται ότι οι σελίδες, οι υπηρεσίες, οι επιλογές και τα περιεχόμενα θα
        παρέχονται χωρίς διακοπή, χωρίς σφάλματα και ότι τα λάθη θα διορθώνονται. Επίσης, δεν
        εγγυάται ότι το ίδιο ή οποιοδήποτε άλλο συγγενικό site ή οι εξυπηρετητές (servers) μέσω των
        οποίων αυτά τίθενται στη διάθεσή των χρηστών/μελών, δεν περιέχουν "ιούς" ή άλλα επιζήμια
        στοιχεία. Το VivianLab δεν εγγυάται σε καμία περίπτωση την ορθότητα, την πληρότητα ή και
        διαθεσιμότητα των περιεχομένων, σελίδων, υπηρεσιών, επιλογών ή τα αποτελέσματά τους.
      </p>
      <p>
        Ανωτέρα Βία Όλα τα γεγονότα που θεωρούνται απρόβλεπτα και ανυπέρβλητα καθιστώντας απολύτως
        αδύνατη την εκτέλεση της συμφωνίας είναι συνήθως φαινόμενα γεγονότων ανωτέρας βίας.
        <br />
        Ως εκ τούτου, το VivianLab δεν δύναται να θεωρηθεί υπεύθυνο απέναντι οποιουδήποτε χρήστη για
        οτιδήποτε, το οποίο διαφορετικά θα μπορούσε να είναι υπεύθυνο, αν είναι αποτέλεσμα γεγονότων
        πέρα από τον έλεγχό του, περιλαμβανομένων μεταξύ άλλων, θεϊκών παρεμβάσεων, πράξεων πολέμου,
        εξέγερσης, τρομοκρατίας, εγκληματικής πράξεως, έλλειψης εργατικού δυναμικού
        (συμπεριλαμβανομένων νόμιμων και παράνομων απεργιών), εμπάργκο, παρεμπόδιση ταχυδρομικών
        υπηρεσιών, διακοπή της επικοινωνίας, μη διαθεσιμότητα επεξεργαστών πληρωμών ή οποιοδήποτε
        άλλο γεγονός πέρα από τον έλεγχό μας. Εάν εντοπιστεί κάποιο γεγονός ανωτέρας βίας, που
        προκαλεί οποιαδήποτε κώλυμα στην εκτέλεση οποιασδήποτε από τις υποχρεώσεις του VivianLab θα
        υπάρξει ειδοποίηση εντός δεκαπέντε (15) ημερών και ενημέρωση για την χρονική περίοδο, την
        οποία εκτιμάται ότι το γεγονός ανωτέρας βίας θα διαρκέσει.
      </p>
      <p>
        Τερματισμός – Αναστολή & Περιορισμός της Εφαρμογής Μη συμμόρφωση οποιουδήποτε χρήστη σχετικά
        με τις διατάξεις που περιγράφονται παραπάνω, συμπεριλαμβανομένης οποιασδήποτε δραστηριότητας
        ειδικά απαγορευμένης από το VivianLab η οποία είναι πιθανόν να δημιουργήσει αστική ευθύνη ή
        / και εγκληματική ή / και ενδέχεται να βλάψει τα δικαιώματα τρίτου, δίνει το δικαίωμα το
        VivianLab να αποσυνδέσει την εφαρμογή και / ή να την αναστείλει χωρίς καθυστέρηση και
        προειδοποίηση και να λήξει αμέσως τη συμφωνία, με την επιφύλαξη οποιασδήποτε αποζημίωσης,
        που μπορεί να ζητήσει.
      </p>
      <p>
        Εν πάση περιπτώσει, τα μέτρα τερματισμού, αναστολής και περιορισμού της εφαρμογής ασκούνται
        ανάλογα με τη σοβαρότητα και την συχνότητα επανεμφάνισης της δυσλειτουργίας.
      </p>
      <p>
        Συμφωνείται εκ των προτέρων, ότι το VivianLab μπορεί να επιβάλει τερματισμό, αναστολή ή
        περιορισμό της εφαρμογής, εφόσον λάβει σχετική ειδοποίηση που κοινοποιήθηκε από αρμόδια
        διοικητική, διαιτητική ή δικαστική αρχή, σύμφωνα με τους νόμους και τους κανονισμούς.
      </p>
      <p>
        Εάν το VivianLab θεωρήσει ότι παραβιάζει ο επαγγελματίας - Πάροχος - χρήστης τους όρους
        χρήσης, μπορεί να τον θέσει αμέσως εκτός λειτουργίας. Στη συνέχεια, θα υπάρξει έγγραφη
        ειδοποίηση ότι έχει ληφθεί ένα τέτοιο μέτρο. Το VivianLab μπορεί, ανά πάσα στιγμή, χωρίς
        προειδοποίηση, να αλλάξει το σχεδιασμό της Υπηρεσίας και των λειτουργιών του ή να διακόψει
        την παροχή της Υπηρεσίας.
      </p>
      <p>Δικαιώματα Πνευματικής & Βιομηχανικής Ιδιοκτησίας</p>
      <p>
        Τόσο εσείς ως επαγγελματίες – Πάροχοι – χρήστες όσο και το VivianLab βασίζεται σε μεγάλο
        βαθμό στο μοναδικό του περιεχόμενο για να διακρίνεται από άλλες ανταγωνιστικές εφαρμογές,
        ανεξάρτητα από το αν αυτές σχετίζονται με την υγεία. Εξαιτίας αυτού, είναι σημαντικό να
        προστατεύει το περιεχόμενο της πλατφόρμας από την αντιγραφή και την αναπαραγωγή του, καθώς
        αφαιρεί τη μοναδικότητά του στα μάτια των καταναλωτών και έχει τη δυνατότητα να μειώσει την
        αξιολόγηση του από τους χρήστες στις διαδικτυακές μηχανές αναζήτησης (browser) λόγω του
        γεγονότος ότι το περιεχόμενό μας θα αναπαράγεται σε διαφορετικούς από το δικό μας
        ιστότοπους, που βρίσκεται αλλού στο διαδίκτυο ή σε εφαρμογές κινητών. Για τον λόγο αυτό,
        συμφωνείται να μην αντιγράφεται, διανέμεται, προβάλλεται, διαδίδεται ή αναπαράγεται με
        οποιονδήποτε άλλο τρόπο οποιαδήποτε πληροφορία στον ιστότοπο ή την εφαρμογή για κινητά,
        χωρίς να έχει χορηγηθεί η προηγούμενη γραπτή άδεια του VivianLab.
      </p>
      <p>
        Εμπορικό Σήμα
        <br />
        Το " VivianLab" είναι ένα εμπορικό σήμα που χρησιμοποιείται από την εταιρεία μας, για να
        πιστοποιούμε με μοναδικό τρόπο τον ιστότοπο που προκύπτει από τις διαδικτυακές αναζητήσεις
        με χρήση των διευθύνσεων www.vivianlab.gr & www.vivianlab.com & www.vivianlab.health Το σήμα
        αυτό είναι γνωστό με βάση το άρθρο 6α της Σύμβασης των Παρισίων για την προστασία της
        βιομηχανικής ιδιοκτησίας και προστατεύεται περαιτέρω από την Διεύθυνση Εμπορικών Σημάτων του
        Συμβουλίου της Ευρωπαϊκής Ένωσης Οδηγία, υπ' αριθμόν 2008/95/ΕΚ.
      </p>
      <p>
        Συμφωνείται να μην χρησιμοποιείται αυτό το όνομα για να προσδιοριστεί ή να προωθηθεί μια
        υπηρεσία ή ένα προϊόν τρίτου μέρους χωρίς την προηγούμενη γραπτή συγκατάθεσή του VivianLab.
        <br />
        Επιπλέον, συμφωνείται να μην γίνει χρήση του εμπορικού μας μανδύα ή αντιγραφή της εμφάνισης
        και της αισθητικής της ιστοσελίδας και της εφαρμογής για κινητά ή του design τους και γενικά
        οποιουδήποτε στοιχείου, χαρακτηριστικού της ταυτότητας της εφαρμογής του VivianLab, χωρίς
        την προηγούμενη συναίνεση της Εταιρείας. Εκχώρηση Δικαιωμάτων
        <br />
        Δεν επιτρέπεται η εκχώρηση πάσης φύσεως δικαιώματος ή / και υποχρέωσης βάσει της παρούσας
        συμφωνίας σε οποιοδήποτε άλλο μέρος χωρίς την προηγούμενη γραπτή συναίνεση του VivianLab.
        Αντίθετα, το VivianLab δύναται να αναθέσει τα δικαιώματα και / ή τις υποχρεώσεις του βάσει
        της παρούσας συμφωνίας σε οποιοδήποτε άλλο μέρος, κατά την κρίση του.
        <br />
        <br />
      </p>
      <p>
        Δίκαιο & Δικαιοδοσία Ο κάθε χρήστης αποδέχεται ρητά ότι την αποκλειστική δικαιοδοσία και
        αρμοδιότητα για οποιαδήποτε διαφορά προκύψει με το VivianLab, την έχουν τα Δικαστήρια της
        Αθήνας και εφαρμοστέο είναι το Ελληνικό δίκαιο και όταν η πρόσβαση στο VivianLab γίνεται από
        χώρα εκτός Ελλάδος. Αν οποιοσδήποτε από τους ανωτέρω όρους κριθεί άκυρος με δικαστική
        απόφαση, αυτό δεν επηρεάζει την εγκυρότητα και ισχύ των υπολοίπων όρων, οι οποίοι παραμένουν
        σε πλήρη ισχύ και εγκυρότητα.
      </p>
      <p>
        Τροποποίηση των όρων χρήσης Το VivianLab δύναται οποιαδήποτε χρονική στιγμή να τροποποιήσει
        τους όρους χρήσεως, την πολιτική προστασίας προσωπικών δεδομένων και όλες τις προϋποθέσεις
        που περιλαμβάνουν, οι δε χρήστες – επισκέπτες ή/και Πάροχος επαγγελματίας οφείλουν κάθε φορά
        να ελέγχουν για ενδεχόμενες αλλαγές και εφόσον εξακολουθούν τη χρήση τεκμαίρεται αμαχήτως,
        ότι αποδέχονται τους τροποποιημένους όρους και προϋποθέσεις και παραχωρούν την συναίνεση,
        συγκατάθεση, συμφωνία και έγκριση τους. Μπορούμε να πραγματοποιήσουμε τέτοιες τροποποιήσεις
        στην εφαρμογή μας για διάφορους νομικούς λόγους, συμπεριλαμβανομένων μεταξύ άλλων των λόγων
        ασφαλείας, πνευματικής ιδιοκτησίας ή άλλους λόγους σύμφωνα με τη δική μας κρίση και δεν
        απαιτείται να σας ενημερώσουμε για τέτοιες τροποποιήσεις.
      </p>
      <p>
        Σημαντική σημείωση Οι βασικές αξίες που διέπουν την παρούσα ιστοσελίδα είναι η εκτίμηση και
        ο σεβασμός. Επομένως, όταν χρησιμοποιείτε το VivianLab παρακαλείστε να τηρήσετε τους
        ακόλουθους κανόνες: Απαγορεύεται η χρήση προσβλητικής, προκλητικής ή απειλητικής γλώσσας.
        Απαγορεύεται η χρήση γλώσσας που εισάγει διακρίσεις όσον αφορά το φύλο, την ταυτότητα ή την
        έκφραση του φύλου, την εθνικότητα, τη θρησκεία ή άλλη πεποίθηση, αναπηρία, σεξουαλικό
        προσανατολισμό ή ηλικία. Δεν επιτρέπονται οι παρενοχλήσεις στο VivianLab. Ως παρενόχληση
        νοούνται εκφράσεις προφορικές ή γραπτές ή / και πράξεις που σκοπεύουν να προκαλέσουν
        οποιαδήποτε ψυχική ή σωματική ζημιά. Σε κάθε περίπτωση που δεν τηρούνται αυτοί οι κανόνες,
        το VivianLab μπορεί να λάβει μέτρα όπως την απενεργοποίηση του λογαριασμού χρήστη ή / και
        της κλήσης της Δίωξης Ηλεκτρονικού Εγκλήματος.
      </p>
      <p>ΟΙ ΠΑΡΟΝΤΕΣ ΟΡΟΙ ΧΡΗΣΗΣ ΤΡΟΠΟΠΟΙΗΘΗΚΑΝ ΤΕΛΕΥΤΑΙΑ ΦΟΡΑ ΤΗΝ 30/10/2023</p>
    </div>
  );
}

export default DoctorTosGreek;
