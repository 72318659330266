import React from 'react';
import css from '../TermsOfServicePage.module.css';

function PatientTermsEnglish() {
  return (
    <div className={css.pageContent}>
      <h2>Terms of Use by Professionals – Providers - Users</h2>
      <h3>Preamble</h3>
      <p>
        The terms of use of the intermediary services provided through the website located at
        www.vivianlab.gr, www.vivianlab.com, & www.vivianlab.health or in the relevant application
        (hereinafter "Company" or "Vivian Lab" as the distinctive title of the Company).
      </p>
      <p>
        The above website (hereinafter the "Website") has been created, is owned, and operated by
        the company under the name “VIVIANLAB SINGLE MEMBER P.C." and the distinctive title “Vivian
        Lab", with headquarters in Athens, street 47 SAMOU STR, with no. G.E.MI. 179795401000, Tax
        NUMBER 802625789, Tax office AMAROUSION, electronic contact info@vivianlab.com. The company
        is also the exclusive legal owner of this Website, its content, and the domain name under
        the international online registration as follows: www.vivianlab.gr, www.vivianlab.com, &
        www.vivianlab.health, and any other similar name with which it is associated or chooses to
        be associated in the future. The general content of these terms of use and all site’s
        property and announcement are protected by copyright law, as well as specific elements of
        the websites are protected by laws related to, but not limited to, commercial secrecy, trade
        & industrial secrets and property, unfair/illegal competition, e-commerce, security of
        financial transactions, and other relevant legislation. The present terms may not be
        modified, copied or imitated, and no copyright or trademark notices or other proprietary
        notices may be removed or altered.
      </p>
      <h3>Acceptance of Terms of Use</h3>
      <p>
        The use of the VivianLab website is the sole responsibility of the professional - Provider -
        user and implies full, unreserved acceptance and agreement on his part, of the following
        terms of use and the terms of the personal data protection policy. The following terms of
        use concern the use of the VivianLab website both by a visiting professional - Provider -
        user and by a professional - Provider - user registered on the website. Use of our website
        and registration on it means acceptance, consent, consent, approval and agreement on the
        part of the visitor - user with the terms of use and the personal data protection policy
        that we follow.
      </p>
      <h3>Online Services</h3>
      <p>
        VivianLab is an innovative website, which specializes in the field of health consulting in a
        general and holistic context in the form of registration and promotion of professionals of
        all branches of health as well as businesses and sciences active in the field of health in a
        wider format and employment. Therefore, VivianLab is an online professional display site
        that in fact includes beyond providers and additional categories of professionals and
        scientists related to the field of health, in an effort to provide the end user with a
        comprehensive solution in seeking advice and help for any health issue, self-improvement,
        counseling and reinforcement psychology, pharmaceutical and/or parapharmaceutical coverage
        and so on. It should be noted that through this website, which operates strictly as an
        intermediary service and not as a provider, various and wide ranged services are offered,
        such as a number of tele-consulting services is provided, online watching audio-visual media
        (i.e. educational videos), reading and becoming aware of articles, participating in group
        webinars & 1:1 online sessions, recipes, possibility of recording symptoms, creating diet
        and exercise plans, receiving advice while creating hotlines with experts for specialized
        questions and of course creating the possibility of booking an appointment online, avoiding
        unnecessary phone calls and travel.
      </p>
      <p>
        More specifically, through VivianLab, professionals - Providers - users can display their
        professional profile, which includes various information such as contact details,
        description of the professional's face, areas in which he specializes, services provided and
        their costs, working hours and optionally is provided through the profile to the interested
        users, whether members or visitors, to schedule their online appointment with the
        professional as well as to make an online consultation video call to receive all kinds of
        advice on issues they face. Also, with regard to companies active in the field of
        consulting, they can display their company profile, which includes information such as
        contact details, description of the company, areas in which it specializes, services
        provided and their costs, promotional packages and offers, opening hours operation and is
        optionally provided through the profile to the interested users to schedule their
        appointment online, as well as to make an online consultation video call to receive advice
        on issues they face. In particular, online consulting services were created and have the
        character of providing consulting, auxiliary and supervisory services to users of
        information and communication technologies for the exchange of valid information and
        reliable communication between the professionals and interested users of VivianLab for the
        diagnosis, treatment and prevention of diseases, the research and assessment of the
        situation or the emerging issue that the professional - Provider - user is called to curate
        and provide the necessary services.
      </p>
      <p>
        Furthermore, the sessions take place via video call either on the Google Meetings platform,
        or via the Vivian Lab/Jitsi platform under the sole discretion and decision of the platform.
        As mentioned before, Vivian Lab is an online electronic mediation service for registered
        users where, amongst other services the user can watch audio-visual media (educational
        videos), read literature, participate in group webinars, record the symptoms and view
        individualized diet and exercise plans, receive advice while enjoying the choice of
        participating in hotlines with experts for specialized questions, book an appointment and
        communicate via chat, messaging, voice call, or video call (Meeting) with a qualified
        Professional and receive information about all kinds of issues concerning wellness. Users
        may join group sessions or hotlines on a first-come, first-served basis, subject to weekly
        availability, with no guarantee of securing a slot; additionally, Vivian Lab is not
        responsible for provider no-shows in group or individual sessions and will not offer
        reimbursement in such cases.
      </p>
      <p>
        In no case does the online consultation service replace the clinical and/or necessary
        examination and the performance of laboratory tests that are necessary in order to establish
        a safe and substantiated scientific diagnosis. A necessary condition for the participation
        of a professional - Provider - user in the online consultation service is the disclosure of
        the practice license number, as provided by the respective medical association or
        alternatively the practice license number, in the case of non-physicians, in order to ensure
        that it is a legal professional - Provider - user recognized by the official Greek state.
        The Provider is solely responsible for submitting true and accurate information and
        guarantees both their legality and their legal possession and use. The Provider is
        responsible for any positive and consequential damages the company may suffer for the
        violation of the above obligation. In no case is VivianLab responsible for the content of
        the advice of each professional user to the interested party, as well as for the management
        of this information on the part of the interested user. In any case, it is emphasized that
        the VivianLab website is an intermediary in the provision of these services, providing only
        the means for the provision of these services.
      </p>
      <p>
        Therefore, these terms of use regulate the relationship between this website, as an
        intermediary in the provision of online counseling, and the end user of the digital service
        either as an interested party, or as a professional - provider and NOT the relationship of
        the latter two and solely responsible for the advice given to those who use the service is
        the professional-user, for the content, practice and correctness of the information it
        provides, and the interested-user, for the management and evaluation of this information.
      </p>
      <p>
        Those Providers, professionals and business users who are active in the area of action and
        mediation of VivianLab who wish to create an online profile on the VivianLab website in
        accordance with the above, are invited to fill in the relevant online registration form with
        their details and submit it in the specified field by accepting the Website's Terms of Use
        and Privacy Policy. The correctness of these is confirmed by VivianLab and subsequently the
        Provider professional / business user joins the network of VivianLab's projected partners
        and has his personal profile. VivianLab does not set a specific cost for the visit, nor does
        it check whether the professional user complies and under which price list regime and
        condition. It is noted that through the website visitors/users are given the opportunity to
        contact VivianLab for any issue, by filling out the corresponding contact form, accepting
        the Website's Terms of Use and Privacy Policy. In particular, regarding the professionals -
        Providers - users, it is explicitly mentioned that the Provider / service provider /
        professional user has been informed and expressly consents to the terms and obligations
        stated in the texts of: 1. Terms of Use, 2. Privacy Protection Policy and 3. Use of Cookies
        which define the following collaboration environment:
      </p>
      <h3>Terminology:</h3>
      <p>
        <strong>1. User:</strong> The final customer/consumer of the company who seeks services
        through the website/platform.
      </p>
      <p>
        <strong>2. Platform and/or Marketplace:</strong> Online environment/e-commerce website that
        enables users to seek services from specialized professionals of their choice, according to
        their availability.
      </p>
      <p>
        <strong>3. Provider and/or Business:</strong> The business / professional user that
        cooperates with the Company, with the aim of either its online presence in the Marketplace
        to provide services to the user of the service. The term "Provider" can refer either to a
        legal entity, or to a natural person representing a business.
      </p>
      <p>
        <strong>4. Company directive:</strong> Any directive or command given through the usual
        communication framework, e.g. e-mail and/or the application, document etc. from the Company
        or the Project Management Team to the Partner regarding the implementation of the Project.
      </p>
      <p>
        <strong>5. Project:</strong> The execution of the flat rate service project as well as any
        kind of interwoven and related service.
      </p>
      <h3>Joining the Website:</h3>
      <p>
        The Partners, Professionals - Providers - Users of the Website contract with the Company by
        fully completing the relevant electronic form found on the Website and accepting these
        terms.
      </p>
      <p>
        The cost of their participation in the Website, either in the form of a periodic
        subscription or/or through another type of costing depending on the pricing policy of the
        Company as the case may be, has already been agreed upon. Professionals that appear in
        Vivian Lab site as Partners are independent professionals and bear responsibility for their
        contributions and instructions, which are given either through the website's educational
        material (articles, videos, etc.) or in session (via video call and/or live).
      </p>
      <h3>Terms of protection:</h3>
      <p>
        The Providers - Professionals - Users of the Website must strictly adhere to both the
        content in this General Terms of Use Framework, as well as each individual condition set
        with the ad hoc contracts they may establish with the Company, while they must, during the
        cooperation with the Website to comply with every established legal provision of both
        National, European and International Law and the Code of Ethical Conduct on the Internet as
        applicable, while it is prohibited to carry out acts or formulate practices that violate the
        rights of the Consumer, constitute misleading advertising or promote unfair competition both
        to the other Website Providers and to the Website itself.
      </p>
      <p>
        The Providers-Professional-Users of the Website undertake and fully assume the relevant
        responsibility to provide the Website with their real personal and professional details,
        such as indicatively their name, the address of their professional headquarters, in the case
        of a legal entity, the full details of the legal their representative and the person
        designated for communication with the Website and the visitors, users or members of the
        person their contact information (i.e. indicative and not limiting landline and mobile phone
        numbers, fax numbers, e-mail address, domain names of professional websites, etc.), their
        exact field of activity and the categories of services provided by them. In particular,
        these data, as well as any other information or digital file that you will make available on
        the Website, should in no case be false, misleading or inaccurate, nor lead, directly or
        indirectly, to cause false representations to third parties or aim to in any way making
        available products or services that are contrary to the current legislation. Furthermore,
        the information provided above should not in any way violate the applicable provisions of
        the Constitution and the Civil, Criminal and Commercial Law, including the provisions
        relating to Consumer Protection and Advertising Law, Unfair Competition, Intellectual and
        Industrial Property and in the Protection of Personality, nor to contain or give birth to
        any kind of discrimination that goes against the value of the human being as well as any
        form of life, while it should be in accordance with all applicable legislation at the
        national, European and international level. It is reiterated that all the necessary
        documents, professional licenses, academic and/or professional certifications and other
        necessary legalizing documents that the Provider must possess are under the sole
        responsibility of the Provider both in terms of their legal acquisition and use, as well as
        in terms of updating and their strength.
      </p>
      <p>
        The Providers - Professional-Users hereby expressly consent and by providing the above
        information and personal data that they provide the Company with their express consent for
        these to be kept in a database with categorization according to their professional activity
        and the type of provided of those Services that the Company maintains and to be used both by
        the latter and by any person who derives rights from it for any communication related to the
        operation of the Website, as well as by any third party, user of the Website or its Partner,
        upon his request for the provision of a Service by him or the provision of a financial
        offer. Communication can be carried out by any convenient means, such as e-mail, sms, video
        call / conference or telephone call.
      </p>
      <p>
        The Providers - Professional-Users of the Website undertake to promote through the Website
        the Services provided by them in a manner consistent with good faith and good morals,
        refraining from any practice that suggests or may contribute in any way to misleading the
        consumer in the defined in Law 2251/1994 on Consumer Protection as applicable, PD 131.2003
        and the existing legislation in electronic commerce and refraining from any practice capable
        of causing damage or malfunction to the network, such as mass sending of messages
        ("spamming") or mass gathering of personal user data for the purpose of advertising outside
        the Website.
      </p>
      <p>
        The Providers - Professional-Users of the Website must respect and contribute to the
        safeguarding of the proper and proper operation of the Website, ensuring that all
        information or digital files that he posts or transmits to the Website will be completely
        checked by his own care so that do not contain any harm software (malware) that can cause
        any damage or delay to the servers or systems of the Company and any third party with which
        it cooperates, and to the computers, electronic devices or servers of other visitors, users
        or members of the Website, such as indicatively viruses, trojans, worms, etc., nor any other
        file or software that would violate the privacy or personal data of the Company, its
        employees, its other professional partners and any visitor, user or registered member of the
        Website.
      </p>
      <p>
        The Providers - Professional-Users of the Website upon starting their cooperation with the
        Company and the Website, expressly provide their consent to accept any kind of
        communications, invitations, requests from visitors, users or members of the Website for
        financial offers in the context of the Operation of the Website.
      </p>
      <p>
        The Providers - Professional-Users of the Website undertake to respond immediately or in any
        case in the absolutely necessary time to every communication of the Company or user of its
        Website Services with them. In case of cooperation of the Provider - Professional-User with
        a user of the Website Services in the context of its operation, the former must demonstrate
        due professionalism and diligence when providing its Services. The Provider - Professional -
        User of the Website has been informed and hereby accepts that the Website may display
        positive or negative reviews of the offered service provided to a user through the Website,
        which may indicatively include ratings or comments directly from the user of the Website
        services.
      </p>
      <h3>Financial Terms – Remuneration</h3>
      <p>
        The Website offers, among other things, subscription services that allow its subscribers to
        access videos, online books and other audiovisual material via computer, tablet, mobile
        phone requiring an Internet connection.
      </p>
      <p>
        It supports either simple browsing by the visitor or the use of subscription services by the
        registered member
      </p>
      <p>
        The subscription fee is set at 45€ for a quarter or 150€ per year, with the understanding
        that this is non-refundable, even in cases where the user has not utilised the services.
      </p>
      <p>
        Additionally, users have the option to register for a complimentary trial period, spanning a
        duration of one quarter.
      </p>
      <p>
        The aforementioned costs include the following: audiovisual media (educational videos),
        reading articles, participating in group webinars, recording symptoms, diet and exercise
        plans, advice, and hotlines with specialists for specialised questions, with the exception
        of personalised ones. In addition to the minimum sessions/appointments with health
        specialists, which can be either in person or by video call, and which incur an additional
        charge, the costs also include watching audiovisual media (educational videos), reading
        articles, participating in group webinars, recording symptoms, diet and exercise plans,
        advice, and hotlines with specialists for specialised questions.
      </p>
      <p>Subscription</p>
      <p>
        The Vivian Lab service is a subscription-based service with automatic renewal. Users are
        required to create a personal account on our website. Your subscription to Vivian Lab may be
        monthly, quarterly, or annual, with automatic renewal, depending on your chosen plan. By
        subscribing, you authorize us to charge your selected subscription cost monthly, quarterly,
        or annually.
      </p>
      <p>Charges</p>
      <p>
        Billing Period: Subscriptions are AUTOMATICALLY charged on a monthly, quarterly, or annual
        basis using the payment method provided during registration. Charges occur on the calendar
        date that corresponds to your subscription start date (i.e., the SUBSCRIPTION RENEWAL DATE).
        In some cases, the billing schedule may vary, such as if the payment method fails or if the
        subscription starts on a date not included in a given month. You can view the next renewal
        date by logging into your account on our website and navigating to the "ACCOUNT" menu.
      </p>
      <p>Payment Methods</p>
      <p>
        Payments are made via credit or debit card. During registration, you will be required to
        select a valid payment method. If a payment fails due to an expired card, insufficient
        funds, or any other reason, and you do not update the payment method or cancel your account
        in time, the company reserves the right to suspend your access to the service until a valid
        payment is processed. Once you update your payment method or replenish your account balance,
        you authorize us to resume charges for the agreed subscription cost (unless you have
        canceled the subscription). This may adjust the dates of your subsequent payments. Payment
        methods may be updated or expanded over time, and you will be notified promptly through an
        agreed communication method.
      </p>
      <p>Access License</p>
      <p>
        The services and educational materials provided by the company are for personal and
        non-commercial use only. During your subscription, you are granted a limited, non-exclusive,
        and non-transferable license to access: Videos, Online books (eBooks), Other available
        audiovisual content. No additional rights are granted beyond this. Public display or reading
        of the content, or any other use beyond private viewing, is strictly prohibited.
        Specifically, you are not permitted to: Reproduce, Exploit, Copy, Publish, Edit the
        company’s content in whole or in part.
      </p>
      <p>Cancellation</p>
      <p>
        You can cancel your subscription at any time. Upon cancellation, you will retain access to
        the service until the end of the current billing period. No refunds are provided for unused
        service periods unless you followed the prescribed cancellation procedure. Refunds or
        withdrawal rights apply only to recurring charges/renewals if a written request is submitted
        via email within 24 hours of the renewal. To cancel, log into your account on the website,
        go to the "ACCOUNT" menu, and select the cancellation option. After completing the
        cancellation process and the expiration of your subscription period, the website will no
        longer charge your card. Refunds are issued for erroneous charges made after cancellation.
      </p>
      <p>Changes to Pricing</p>
      <p>
        Vivian Lab reserves the right to adjust subscription fees periodically. Any changes will not
        affect your current charges until the end of the agreed subscription period. Notification
        will be provided in advance. If you do not agree with the new fees, you must follow the
        cancellation procedure outlined in this document.
      </p>
      <p>Additional Services</p>
      <p>
        The registered member may have an additional session/appointment with a professional of
        their choice. The cost of each session/appointment is determined exclusively by the
        professional.
      </p>
      <p>
        Especially for a session via video call: Up to 24 hours prior to the scheduled appointment,
        you may cancel the session/communication/video call and receive a full refund of the money
        paid. Please note that due to the processes involved, it may take up to 14 days for your
        funds to be returned to your account. For a video call that is canceled up to 60 minutes
        before it takes place, there are cancellation fees and you are not entitled to a refund.
      </p>
      <p>
        In the event of a loss of connection during the Meeting, we will make every effort to
        restore it. In the event that this is not possible and the connection is not restored, you
        have the right to repeat it free of charge at the next available appointment.
      </p>
      <p>Indicatively, the payment methods are the following:</p>
      <p>
        ~ Payments are made through the ¨stripe¨ application, where of course the user of the
        application organizes his account for its operation, automatically and automatically accepts
        its Terms and Conditions and then follows the steps required by the application to complete
        the process and to carry out all kinds of financial transactions / payments. Vivian Lab does
        not participate in any way in this process and therefore does not bear any responsibility
        regarding any kind of financial transaction / payment made or not. For details you can read
        http://vivianlab.com/faq
      </p>
      <p>
        The user - guest or member - must add their credit card to book an appointment. The credit
        card is not charged, only pre-authorized and the amount is reserved for making the
        communication.
      </p>
      <p>
        ~ If the communication takes place in any way as specified, the Professional - Provider must
        click on an option received by email or on the online payment platform and confirm the
        transaction within 7 days in order for the payment to take place.
      </p>
      <p>
        ~ As soon as he selects the charge / accept option in the relevant field, the user's credit
        card is charged within 24 hours and the Professional - Provider - User receives his money to
        the bank account he provided through the 'stripe' application within 5 days.
      </p>
      <p>
        ~ The professional - Provider - user is responsible to send the user the relevant receipt /
        document for the session / service performed by e-mail directly to the guest user - member
        to whom he offered his services according to the personal tax and accounting regime in which
        it belongs to.
      </p>
      <p>
        ~ If the professional – Provider – User does not confirm the payment within 7 days, they
        will never receive the payment even though the session took place and the money will be
        returned to the user's credit card within 7 days.
      </p>
      <p>
        ~ In addition to the above, to confirm the service offered through Vivian Lab, Vivian Lab's
        automated system issues proof of service provision for use by the application / platform. In
        no case does the proof of the automated system replace or replace the tax document that the
        professional - Provider - user chooses or is obliged to issue for his own tax and accounting
        reasons and forwards to the user who received the service as above.
      </p>
      <p>
        ~ Vivian Lab has the right to refund the user if the company believes that the procedure or
        service was not fulfilled or completed as intended. In this case, even if the Professional
        has already received their funds in the bank account through the online application, Vivian
        Lab may withdraw them from the Professional's account and return them to the user.
      </p>
      <p>
        ~ It is noted that Vivian Lab does not charge any amount for the mediation services it
        offers and does not seek income in this way
      </p>
      <p>
        ~ When the professional – Provider – user adds his credentials and details for the process,
        he directly accepts its Terms and Conditions and therefore Vivian Lab is not responsible for
        the payments and the overall process.
      </p>
      <p>
        ~ If there was a technical problem and the Professional - Provider - User or Guest - Member
        user could not participate in the meeting because the online connection was not possible or
        because the platform was not working, then Vivian Lab may cancel the session will not there
        is a charge and therefore the professional - Provider - user is not entitled to receive
        payment, This remains at the discretion of Vivian Lab.
      </p>
      <p>
        ~ Vivian Lab has full discretion and authority to cancel payments, refund users, not pay the
        professional – Provider – user if part or all of the process was not followed. Vivian lab
        does not need to explain to any related party about this decision. In particular, in the
        event that a professional - Provider - user cancels four (4) appointments scheduled through
        Vivian Lab for the provision of services, Vivian Lab is automatically granted the right to
        terminate any kind of cooperation with the professional - Provider - user and remove the
        listing it from the platform options.
      </p>
      <p>
        All payments, made using credit, debit and prepaid cards, are processed through the digital
        electronic payment platform 'stripe' which meets the specifications imposed by the standards
        for the security of payment card data. At the same time, during electronic card payments,
        strong customer identification is applied according to the 3-D secure protocol, in
        compliance with the European Directive 2015/2366 (PSD2) and the requirements for strong
        customer identification (Strong Customer Authentication.
      </p>
      <h3>Service Provision - Consulting:</h3>
      <p>
        During the validity of the partnership and therefore the use of the website by the
        professional partner for the provision of services to the end user, the Provider will
        perform a fixed set of services for the Company, as specified herein.
      </p>
      <p>
        As noted above, the Professional - Provider – user is a legal or natural person who has
        understood the purpose of the company and its mode of operation and after having read the
        general and more specific terms of the website, agrees and undertakes to carry out the
        Partner's work at a flat rate in collaboration with the company. The environment and the
        cooperation agreement, however it is defined, defined or implemented, in no case constitutes
        an exclusive cooperation agreement and does not limit the Partner's right to provide
        services or any kind of cooperation to third parties or to carry out other business
        activities and to provide further services independent of VivianLab if desired. Therefore,
        the cooperation between the professional partner and VivianLab does not in any way create a
        relationship of dependent or independent work between the company and the Partner and they
        remain independent professionals for the purposes of this Agreement. The Provider
        acknowledges and expressly declares that he is an independent Provider of the company and
        that nothing in the cooperation or the nature of the performance of the services he offers
        under the terms and agreements that define the cooperation environment makes him an employee
        or employee of the company and should not be considered the himself as such. Accordingly,
        the Provider does not have and will not exercise or be deemed to have any authority to enter
        into or enter into any contract or undertake any commitment or obligation for, on behalf of,
        or on behalf of VivianLab. Because reliability and accuracy are tools for the smooth
        operation of the collaboration, the Provider is obliged to observe the times, as defined by
        the platform, and in particular to provide the agreed service within the time indicated on
        the platform on a case-by-case basis. In case of inability/possibility to complete or meet
        the times, he must inform the company in writing in time. Likewise, in the event of the
        Partner not being able or able to perform the work, he must inform the company.
      </p>
      <p>
        Likewise, it is necessary to have the necessary certifications, licenses and legalization
        documents and therefore the Provider is responsible for obtaining any necessary licenses or
        registrations required to perform the service it offers in addition to, if applicable, all
        professional licenses. In addition, he must hand over to the company a copy of the above and
        of course he is obliged to inform the company in case of expiration or loss or termination
        of validity of the above licenses.
      </p>
      <p>
        As difficulties and failures are part of the professional activity the Provider is solely
        responsible for ensuring that it is insured with the full coverage required to perform the
        service it offers. Confidentiality and trust are important terms of cooperation and for this
        reason both VivianLab and the Provider are committed to a regime of complete confidentiality
        and protection of the privacy of the cooperation between them, and the Provider expressly
        declares that it will refrain from any action to use or benefit from the information it
        receives from the users of the Platform for any other purpose, except for the execution of
        each stage of the service it has exclusively undertaken. In any case, all partners must act
        in good faith and business ethics and with the aim of proper and orderly cooperation. To
        this end, a duty of notification is defined regarding any kind of harmful or difficult
        incident that may come to the attention of one or the other contracting party with an
        obligation to inform immediately and without delay and propose help to deal with and resolve
        it.
      </p>
      <p>
        Because each professional – Provider - User knows best the level of their work, they retain
        the exclusive right and responsibility to determine the manner, method and means by which
        the necessary actions will be performed to perform part or all of the project and/or service
        who offers, furthermore, acts without thorough control and explicit instructions from the
        Company on his own schedule and time under the schedule that the platform sets for the
        visitor - user, in combination with the effectiveness and perfection of his actions in the
        context of professional and of his scientific training in the form of the contractor. The
        company will have no obligation to provide training to the Provider for the execution of the
        agreed upon execution of the cooperation.
      </p>
      <h3>Individual:</h3>
      <p>
        It is expressly prohibited for all users, i.e. visitor – user or professional Provider to
        create fake accounts with fraudulent intentions, to collect or store personal data of other
        users in any way. VivianLab reserves the right to seek satisfaction for any damages it may
        incur from the violation of the above prohibitions by any user and to take any relevant
        legal action, process and take legal remedial out-of-court or judicial measures aimed at
        preventing a violation of these terms. of use. Each visitor or professional - Provider -
        user is responsible for the use of VivianLab and may use its services only for legal and
        legitimate purposes. No one may use VivianLab in a way that could damage, disable, or impair
        our servers or networks, or in ways that interfere with any other visiting member's use and
        exploitation of VivianLab's services - user partner. The visitor - user is prohibited from
        illegally abusing our services by planning an appointment with a Provider - professional -
        user which he does not intend to keep.
      </p>
      <p>
        The information and presentations provided on the site - portal express the personal
        opinions of the authors - partners, are exclusively informative and advisory in nature and
        cannot replace the advice and positioning of the specialized professional partners -
        Providers. The user-visitor should not rely on the information provided on VivianLab as a
        substitute for scientific advice and placement, nor does it in any way replace the
        professional advice, examination, diagnosis and treatment individually recommended to the
        user by the professional user. Therefore, the content of the Website such as indicative and
        not limiting, texts, images and complete offers presented by its authors, but also any other
        material included on the website is used for the sole purpose of informing and informing its
        visitors - users It is specifically mentioned that no statement or posting on VivianLab can
        be taken as an exercise of professional and specialized consulting service and care. The
        user - visitor makes use of VivianLab, the proposed professionals - partners and the
        provided services exclusively and solely at his own risk. VivianLab does not bear any kind
        of responsibility for cancellations or unfulfilled meetings (appointments) with the
        visitor-user or with the professional user and / or any kind of damage arising to the users
        from them.
      </p>
      <p>
        Note to professional Providers - users that VivianLab may provide links to third party
        websites. It may also select and prioritize specific web pages based on user-entered search
        terms, allow its advertisers to respond to search terms with advertisements or sponsor
        content. In no case does VivianLab recommend or endorse the content from third-party
        websites, it does not control the availability, content, privacy policy, quality and
        completeness of the services of other websites and websites to which it refers through
        "links". , hyperlinks or advertising banners. Thus, for any problem that occurs during their
        visit-use, the respective user, i.e. visitor, whether member or professional, must contact
        the respective web sites and pages directly, which bear full responsibility for the
        provision of their services. VivianLab should in no way be considered to adopt or accept the
        content or services of the web sites and pages to which it refers, to be associated with
        them in any other way or to guarantee them. VivianLab has no responsibility for any kind of
        communication of the user-visitor with the third-party service providers advertised on
        VivianLab and for any transaction that may arise from the relationship between them.
        However, if errors are pointed out by the visitor-user and/or the
        professional-provider-user, VivianLab will seek to immediately make every effort to correct
        them. Nevertheless, each user uses at his sole responsibility any information and material
        that is available and contained in this website and is provided without any guarantee
        whatsoever for their correctness and accuracy and VivianLab does not bear any
        responsibility, direct or indirect for any damage (positive or negative) that may occur to
        anyone from the use of its website or elements contained therein. VivinLab reserves the
        exclusive right at any time, without warning the users, to make changes and improvements
        that at its discretion will make the operation of the website easier.
      </p>
      <p>
        The use of the VivianLab website and all its services and content are subject to the above
        terms of use. If the Provider - professional user considers these conditions unacceptable
        for him, for any reason, he is requested not to use the website. The use of the VivianLab
        website by the professional - partner constitutes full acceptance, consent, consent,
        approval and agreement on his part of all these terms of use and the terms of the personal
        data protection policy.
      </p>
      <h3>Limitation of Liability</h3>
      <p>
        THE PURPOSE OF THE SITE IS FOR INFORMATIONAL AND INTERMEDIATE PURPOSES AND IS NOT A
        SUBSTITUTE OF A MEDICAL ACTION / SERVICES OR PROVIDES MEDICAL ADVICE
      </p>
      <p>
        In no event shall VivianLab be liable to the service provider for any of its or third
        parties' losses or expenses or for any matters relating to the services offered, advice or
        any other aspects of its overall cooperation or obligations, including any loss of
        compensation or revenue or loss of profits or special, incidental, punitive or consequential
        damages advised of the possibility of such damages, but also against the visitor - user.
        Professional Provider shall indemnify, defend and hold VivianLab harmless from and against
        any and all claims, liability, actions, damages, losses and judgments of third parties, and
        shall pay all costs (including attorneys' fees and costs) to in connection with the
        preparation or defense of any pending or threatened claim or any action or proceeding
        arising out of the provision of services under the partnership.
      </p>
      <p>
        Given the nature and volume of the internet, under any circumstances, including the case of
        negligence, VivianLab is not responsible for any form of damage suffered by the visitor/user
        of the pages, services, options and contents of which he proceeds on his own initiative.
      </p>
      <p>
        Its contents are provided "as is" without warranty of any kind either expressed or implied.
        To the fullest extent permitted by law, VivianLab disclaims all warranties, express or
        implied, including, but not limited to, implied warranties of merchantability and fitness
        for a particular purpose. VivianLab does not warrant that the pages, services, options and
        contents will be provided uninterrupted, error-free and that errors will be corrected. Also,
        it does not guarantee that the same or any other related site or the servers through which
        they are made available to users/members, do not contain "viruses" or other harmful
        elements. VivianLab does not under any circumstances guarantee the correctness, completeness
        or availability of the contents, pages, services, options or their results.
      </p>
      <h3>AI Assistant</h3>
      <p>
        The website has integrated an artificial intelligence tool which is still under development.
        The tool's function is to search the website's content for responses to the queries it is
        presented with, and to formulate these responses accordingly. The processing of data entered
        into the Assistant is governed by the Website's general Data Protection policy.
      </p>
      <br />
      NOTE: THE ASSISTANT DOES NOT PROVIDE MEDICAL ADVICE IN ANY WAY
      <h3>Force Majeure</h3>
      <p>
        All events that are considered unforeseeable and insurmountable making the execution of the
        agreement absolutely impossible are usually phenomena of force majeure.
      </p>
      <p>
        Accordingly, VivianLab cannot be held liable to any user for anything that it might
        otherwise be liable for, if it is the result of events beyond its control, including but not
        limited to acts of God, acts of war, insurrection, terrorism, criminal act, labor shortage
        (including legal and illegal strikes), embargo, obstruction of postal services, disruption
        of communication, unavailability of payment processors or any other event beyond our
        control.
      </p>
      <p>
        If an event of force majeure is detected, which causes any obstacle to the performance of
        any of VivianLab's obligations, there will be a notification within fifteen (15) days and an
        update on the period of time, which it is estimated that the event of force majeure will
        last.
      </p>
      <h3>Termination – Suspension & Limitation of Application</h3>
      <p>
        Failure of any user to comply with the provisions described above, including any activity
        specifically prohibited by VivianLab which is likely to create civil and/or criminal
        liability and/or may harm the rights of a third party, entitles VivianLab to disconnect the
        application and / or suspend it without delay and notice and immediately terminate the
        agreement, without prejudice to any compensation, which it may request.
      </p>
      <p>
        In any case, the measures of termination, suspension and limitation of the application are
        exercised according to the severity and frequency of recurrence of the malfunction. It is
        agreed in advance that VivianLab may terminate, suspend or restrict the Application upon
        receiving a relevant notice served by a competent administrative, arbitral or judicial
        authority, in accordance with laws and regulations. If VivianLab deems that the professional
        - Provider - user is in violation of the terms of use, it may immediately disable it. There
        will then be written notification that such action has been taken. VivianLab may, at any
        time, without notice, change the design of the Service and its functions or discontinue the
        provision of the Service.
      </p>
      <h3>Intellectual & Industrial Property Rights</h3>
      <p>
        Both you as a professional Provider – user and VivianLab rely heavily on its unique content
        to distinguish itself from other competing applications, regardless of whether they are
        health-related. Because of this, it is important to protect the content of the platform from
        being copied and reproduced, as it removes its uniqueness in the eyes of consumers and has
        the potential to reduce its evaluation by users in Internet search engines (browsers) due to
        the fact that our content will be reproduced on websites other than our own, located
        elsewhere on the web or in mobile applications; Therefore, it is agreed not to copy,
        distribute, display, disseminate or otherwise reproduce any information on the website or
        mobile application without the prior written permission of VivianLab.
      </p>
      <h3>Trademark</h3>
      <p>
        "VivianLab" is a trademark used by our company to uniquely identify the website that results
        from online searches using the addresses www.vivianlab.gr, www.vivianlab.com &
        www.vivianlab.health. This trademark is known based on Article 6a of the Paris Convention
        for the Protection of Industrial Property and further protected by the Directorate of Trade
        Marks of the Council of the European Union Directive, number 2008/95/EC. It is agreed not to
        use this name to identify or promote a service or a third-party product without VivianLab's
        prior written consent. In addition, it is agreed not to use our trademark or copy the
        appearance and aesthetics of the website and mobile application or their design and in
        general any element, characteristic of the identity of the VivianLab application, without
        the prior consent of the Company.
      </p>
      <h3>Assignment of Rights</h3>
      <p>
        No right and/or obligation of any kind under this agreement may be assigned to any other
        party without the prior written consent of VivianLab. Conversely, VivianLab may assign its
        rights and/or obligations under this agreement to any other party, at its discretion.
      </p>
      <h3>Law & Jurisdiction</h3>
      <p>
        Each user expressly accepts that the Courts of Athens have the exclusive jurisdiction and
        competence for any dispute arising with VivianLab, and Greek law is applicable even when
        VivianLab is accessed from a country outside of Greece. If any of the above conditions is
        deemed invalid by a court decision, this does not affect the validity and effectiveness of
        the remaining conditions, which remain in full force and effect.
      </p>
      <h3>Compliance </h3>
      <p>
        By using the website's services (www.Vivianlab.com), you agree to abide by all applicable
        laws, regulations, and restrictions. You also agree not to: Archive, download, reproduce,
        transmit, display, or perform content provided by the website, Circumvent, delete, modify,
        disable, or interfere with any content protection, Use automated tools to access the
        website, Reverse-engineer or disassemble any software, products, or processes accessible via
        the website, Upload, send, or transmit any material intended to disrupt, damage, or limit
        the functionality of the website’s software, hardware, or equipment. The company may
        terminate or limit your access to its services if it reasonably believes you have violated
        these terms or engaged in illegal or fraudulent activity.
      </p>
      <h3>Modification of the Terms of Use</h3>
      <p>
        VivianLab may at any time modify the terms of use, the personal data protection policy and
        all the conditions they include, and the users - visitors and/or Professional Provider must
        check each time for possible changes and if they continue the use it is presumed without
        dispute, that they accept the modified terms and conditions and give their consent, consent,
        agreement and approval. We may make such modifications to our Application for various legal
        reasons, including but not limited to security, intellectual property or other reasons in
        our sole discretion and are not required to notify you of such modifications.
      </p>
      <h3>Important Note</h3>
      <p>
        The main values that govern this website are appreciation and respect. Therefore, when using
        VivianLab please observe the following rules: The use of offensive, provocative or
        threatening language is prohibited. The use of language that discriminates against gender,
        gender identity or expression, nationality, religion or other belief, disability, sexual
        orientation or age is prohibited. Harassment is not allowed on VivianLab. Harassment means
        verbal or written expressions and/or actions that intend to cause any mental or physical
        harm. In any case that these rules are not strictly followed, VivianLab may take measures
        such as deactivating the user's account and/or calling Cybercrime Prosecution.
      </p>
      <p>
        <strong>THESE TERMS OF USE WERE LAST AMENDED ON 12/03/2025</strong>
      </p>
    </div>
  );
}

export default PatientTermsEnglish;
